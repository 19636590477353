<template>
    <form
        @submit="valider"
        novalidate="true"
    >
        <div class="cadre content-cadre background-white">
            <div class="cadre-tete cadre-tete--menu">
                <span>
                    <span v-if="$dossier.professionnel">Associé</span>
                    <span v-if="!$dossier.professionnel">Emprunteur</span>
                </span>
                <formulaire-status
                    :form="$v"
                ></formulaire-status>
            </div>
            <div
                id="formulaire"
                class="cadre-content"
                v-if="formulaire"
            >
                <div
                    id="naissance_date"
                    class="form-group"
                    v-bind:class="{'form-group--error': $v.$dirty && $v.schema.naissance_date.$error}"
                >
                    <label> Date de naissance * </label>
                    <datepicker
                        input-class="form-control"
                        :calendar-button="true"
                        calendar-button-icon="far fa-calendar-alt"
                        format="DD/MM/YYYY"
                        placeholder="Date"
                        v-model="$v.schema.naissance_date.$model"
                        :language="$utils.language"
                        initial-view="year"
                        :typeable="true"
                    ></datepicker>
                    <div>
                        <div
                            class="form-info"
                            v-if="age"
                        >{{age}}
                        </div>
                        <div
                            class="form-error"
                            v-if="$v.$dirty && $v.schema.naissance_date.$invalid"
                        >
                            Champs invalide
                        </div>
                    </div>
                </div>
                <div
                    id="naissance_lieu"
                    class="form-group"
                    v-bind:class="{'form-group--error': $v.$dirty && $v.schema.naissance_lieu.$error}"
                >
                    <label> Lieu de naissance * </label>
                    <input
                        class="form-control"
                        placeholder="Lieu"
                        v-model="$v.schema.naissance_lieu.$model"
                        autocomplete="disabled"
                    />
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.naissance_lieu.$invalid"
                    >
                        Champs invalide
                    </div>
                </div>
                <div
                    id="nationalite"
                    class="form-group"
                    v-bind:class="{'form-group--error': $v.$dirty && $v.schema.nationalite.$error}"
                >
                    <label> Nationalité * </label>
                    <input
                        class="form-control"
                        placeholder="Nationalité"
                        v-model="$v.schema.nationalite.$model"
                        autocomplete="disabled"
                    />
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.nationalite.$invalid"
                    >
                        Champs invalide
                    </div>
                </div>
                <div
                    id="situation_familiale"
                    class="form-group"
                    v-bind:class="{'form-group--error': $v.$dirty && $v.schema.situation_familiale_id.$error}"
                >
                    <label> Situation familiale * </label>

                    <select
                        class="form-control"
                        v-model.number="$v.schema.situation_familiale_id.$model"
                    >
                        <option :value="null">Sélectionner</option>
                        <option v-for="option in formulaire.constantes.situationsFamiliales"
                                v-bind:value="option.id"
                                v-html="option.lib">
                        </option>
                    </select>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.situation_familiale_id.$invalid"
                    >
                        Champs invalide
                    </div>
                </div>
                <transition
                    name="fade"
                    mode="out-in"
                >
                    <div
                        id="regime_matrimonial"
                        class="form-group"
                        v-if="$v.schema.situation_familiale_id.$model === 2"
                        v-bind:class="{'form-group--error': $v.$dirty && $v.schema.regime_matrimonial_id.$error}"
                    >
                        <label> Régime matrimonial * </label>
                        <select
                            class="form-control"
                            v-model.number="$v.schema.regime_matrimonial_id.$model"
                        >
                            <option value="null">Sélectionner</option>
                            <option v-for="option in formulaire.constantes.regimesMatrimoniaux"
                                    v-bind:value="option.id"
                                    v-html="option.lib">
                            </option>
                        </select>
                        <div
                            class="form-error"
                            v-if="$v.$dirty && $v.schema.regime_matrimonial_id.$invalid"
                        >
                            Champs invalide
                        </div>
                    </div>
                </transition>
                <div
                    id="mariage_date"
                    class="form-group"
                >
                    <label> Date de {{unionLabel}} </label>
                    <input
                        type="text"
                        :placeholder="`Date de ${unionLabel}`"
                        class="form-control"
                        v-model="$v.schema.mariage_date.$model"
                        autocomplete="disabled"
                    />
                </div>
                <div
                    id="enfants_nombre"
                    class="form-group"
                >
                    <label> Nombre d'enfants </label>
                    <input
                        class="form-control"
                        placeholder="Nombre d'enfants"
                        v-model.number="$v.schema.enfants_nombre.$model"
                        autocomplete="disabled"
                    />
                </div>
                <div
                    id="enfants_ages"
                    class="form-group"
                >
                    <label> Âge des enfants </label>
                    <input
                        class="form-control"
                        placeholder="Âge des enfants"
                        v-model="$v.schema.enfants_ages.$model"
                        autocomplete="disabled"
                    />
                </div>
                <div
                    id="ci_date_validite"
                    class="form-group"
                >
                    <label> Date de validité Carte Identité </label>
                    <datepicker
                        input-class="form-control"
                        format="DD/MM/YYYY"
                        :calendar-button="true"
                        calendar-button-icon="far fa-calendar-alt"
                        placeholder="Date"
                        v-model="$v.schema.ci_date_validite.$model"
                        :language="$utils.language"
                        initial-view="year"
                        :typeable="true"
                    ></datepicker>
                </div>
            </div>

            <div class="cadre-pied background-primary">
                <button
                    type="button"
                    @click="formulaireAnnuler"
                    class="btn btn-transparent cancel-button"
                >
                    Annuler
                </button>
                <button
                    type="submit"
                    class="btn btn-transparent"
                >
                    Enregistrer
                </button>
            </div>

            <transition
                name="notification-afficher">

                <notification
                    v-if="notification.afficher"
                    :content="notification.texte"
                    :type="'success'"
                ></notification>
            </transition>

            <transition
                name="notification-afficher">
                <notification
                    v-if="enregistrement.afficher"
                    :content="enregistrement.texte"
                    :type="'warning'"
                ></notification>
            </transition>
        </div>
    </form>
</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'
    import dayjs from 'dayjs'

    export default {
        name: 'Personnel',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        beforeRouteUpdate(to, from, next){
            this.scrollTo(to)
            next()
        },
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema) : {}
            }
        },
        data() {
            return {
                formulaire: null,
                schema: {},
            }
        },
        computed: {
            age() {
                if (!this.schema.naissance_date) {
                    return null
                }
                const nbAnnees = dayjs().diff(dayjs(this.schema.naissance_date), 'years')
                return `${nbAnnees} an(s)`
            },
            unionLabel() {
                return this.$v.schema.$model.situation_familiale_id === 5 ? 'PACS' : 'mariage'
            }
        },
        created() {
            const {dossierId, id} = this.$route.params

            this.$formulaire.charger('commun', 'Personnel')
                .then((formulaire) => {
                    this.formulaire = formulaire
                    this.schema = formulaire.schema()

                    if (id)
                        api.personnelCharger({dossierId, emprunteurId: id})
                            .then(d => {
                                this.schema = d.data
                                this.scrollTo(this.$route)
                            })
                })

        },
        methods: {
            enregistrer(next = null) {

                return api.personnelModifier({dossierId: this.$route.params.dossierId, emprunteur: this.$v.schema.$model})
                    .then(d => {
                        this.notificationAfficher().then(() => {
                            if (!next) {
                                this.menuContinuer()
                            }
                        })
                    })
            }
        }
    }
</script>
