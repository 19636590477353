<template>
    <div
        class="text-warning"
    >
        Veuillez renseigner au moins un représentant légal pour compléter l'étape
    </div>
</template>

<script>
    export default {
        name: 'MenuWarning',
        props: {
            menu: Array
        },
    }
</script>

<style lang="scss">
    @import "../assets/scss/colors";

    .text-warning {
        color: #ff5722;
        padding: 30px;
        border: 1px solid #ff5722;
        margin-bottom: 20px;
    }
</style>
