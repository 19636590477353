import { Formulaire } from '@/formulaires/Formulaire'
import { minValue, required } from 'vuelidate/lib/validators'


export class ConfigurationDevises extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'devises', cle: 'devises'},
    ]

    schema() {
        return {
            devise_multiple: null,
            devise_1_id: '',
            devise_2_id: '',
            devise_taux_change: ''
        }
    }

    validations(schema) {
        return {
            devise_multiple: {},
            devise_1_id: {
                required: schema.devise_multiple ? required : false
            },
            devise_2_id: {
                required: schema.devise_multiple ? required : false
            },
            devise_taux_change: {
                required: schema.devise_multiple ? required : false
            }
        }
    }

}

export class ConfigurationGeneral extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'prestationTypes', cle: 'prestationTypes'},
        {type: 'constantes', fonction: 'mandants', cle: 'mandants'},
        {type: 'constantes', fonction: 'dossierProvenances', cle: 'dossierProvenances'}
    ]

    schema() {
        return {
            reference_prefixe: '',
            reference_suffixe: '',
            date_saisie: '',
            prestation_type_id: null,
            mandant_id: null,
            franchise_seuil_provenance_code: null,
            logo_pro_utiliser: false,
        }
    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            reference_prefixe: {
                required
            },
            reference_suffixe: {
                required
            },
            date_saisie: {
                required
            },
            prestation_type_id: {
                required
            },
            mandant_id: {
                required: customValidations.mandant_id ? required : false,
            },
            franchise_seuil_provenance_code: {
                required: customValidations.franchise_seuil_provenance ? required : false,
            },
            logo_pro_utiliser: {}
        }
    }

    customValidations(schema, meta) {

        const {compte, dossier} = meta

        return {
            mandant_id: compte.pack === 'Licence Courtisia Mandataire' && compte.hybride,
            franchise_seuil_provenance_code: (
                compte.franchise_seuil_active
                && (!compte.hybride
                    || (compte.hybride && schema.mandant_id && schema.mandant_id > -1))
                && (
                       (compte.niveau === 'MANDANT' && compte.societe_id !== dossier.possesseur.societe_id)
                    || (compte.niveau === 'LICENCIE' && (compte.societe_id !== dossier.possesseur.societe_id || compte.franchise_provenance_active))
                    || ((compte.niveau === 'MANDATAIRE' || compte.niveau === 'SOUS-LICENCIE') && compte.franchise_provenance_active)
                )
            ),
            logo_pro_utiliser: compte.logo_pro || (compte.mandant && compte.mandant.logo_pro)
        }

    }

}

export class ConfigurationApporteur extends Formulaire {

    meta = [
        {type: 'donnees', fonction: 'apporteursLister', cle: 'apporteurs'},
    ]

    schema() {
        return {
            retrocommission_active: false,
            retrocommission_apporteur_id: null,
            retrocommission_contact_id: null,
            retrocommission_contact: ''
        }
    }

    validations(schema, contexte) {
        const customValidations = this.customValidations(schema, contexte)
        return {
            retrocommission_active: {},
            retrocommission_apporteur_id: {
                required: customValidations.retrocommission_apporteur_id ? required : false
            },
            retrocommission_contact_id: {
                required: customValidations.retrocommission_contact_id ? required : false
            },
            retrocommission_contact: {
                required: customValidations.retrocommission_contact ? required : false,
            }
        }
    }

    customValidations(schema, meta) {
        const {compte, apporteurs} = meta
        const apporteur = apporteurs.find((a) => parseInt(a.id) === schema.retrocommission_apporteur_id)
        const contacts = apporteur ? apporteur.contacts : []
        return {
            retrocommission_active: !compte.dossier_origine_requise,
            retrocommission_apporteur_id: compte.dossier_origine_requise || schema.retrocommission_active,
            retrocommission_contact_id: contacts.length && !(apporteur ? apporteur.saisie_manuelle : false),
            retrocommission_contact: apporteur ? apporteur.saisie_manuelle : false,
        }

    }

}

export class ConfigurationPresentationProjet extends Formulaire {

    meta = []

    schema() {
        return {
            presentation: null,
            presentation_texte: '',
            presentation_sous_titre: ''
        }
    }

    validations(schema) {
        return {
            presentation: {},
            presentation_texte: {
                required: schema.presentation ? required : false,
            },
            presentation_sous_titre: {}
        }
    }

}

export class PiecesAFournir extends Formulaire {
    meta = [
        {type: 'constantes', fonction: 'pieces', cle: 'pieces', recharger: true},
    ]

    schema() {
        return {
            piece_ids: []
        }
    }

    validations(schema) {
        return {
            piece_ids: {}
        }
    }
}

export class TypeFormulaire extends Formulaire {
    meta = []

    schema() {
        return {
            carcasse_express: -1
        }

    }

    validations(schema) {
        return {
            carcasse_express: {
                required,
                minValue: minValue(0)
            }
        }
    }
}
