import axios from 'axios'

class Api {

    constructor(url) {

        this.axios = axios.create({
            baseURL: url,
            transformResponse: [function (data, headers) {
              if(headers['content-type'] === 'application/json'){
                const fn = window.JSON.parse || window.JSON.decode
                return fn(data);
              }
              return data
            }]
        })

        /*this.axios.interceptors.response.use((response) => {
            return response
        }, (err) => {
            if (err.response.status === 403) {

            }
        })*/
    }

    configurer(token, cb) {
        //token = '311ff989413948b25a4a22139d90366d'
        this.axios.defaults.headers.common['jeton'] = token
        this.axios.interceptors.response.use((response) => {
            return response
        }, (err) => {
            console.log(err, err.response)
            if (err && err.response && err.response.status === 403) {
                cb()
            }
            return Promise.reject(err)
        })
    }
    arborescenceJustifAfficher(dossierId) {
        return this.axios.get(`/dossiers/${dossierId}/arborescence`)
    }
    documentJustifAfficher(dossierId){
        return this.axios.get(`/dossiers/${dossierId}/arborescence/documents/documentJustif`)
    }
    fichierUploader(dossierId,documentId,documentFolderId,fichier,pieceId) {
        var formData = new FormData();
        formData.append("fichier", fichier);
        return this.axios.post(`/dossiers/${dossierId}/arborescence/documents/${documentId}/${documentFolderId}/fichier/${pieceId}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
    }
    documentsCommerciauxAfficher(dossierId){
        return this.axios.get(`/dossiers/${dossierId}/arborescence/documents/documentsCommerciaux`)
    }
    listerPieceLib(dossierId){
        return this.axios.get(`/dossiers/${dossierId}/arborescence/documents/documentJustif/piece`)
    }

    dossierContexteCharger(dossierId) {
        return this.axios.get(`/dossiers/${dossierId}/contexte`)
    }

    dossiersAfficher() {
        return this.axios.get(`/dossiers`)
    }

    dossierCreer({dossier}) {
        return this.axios.post(`/dossiers`, dossier)
    }

    dossierCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}`)
    }

    compteCharger() {
        return this.axios.get('/utilisateurs/compte')
    }

    entitesCharger() {
        return this.axios.get('/utilisateurs/compte/entites')
    }

    navigationCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/formulaire/navigation`)
    }

    emprunteurCreer({dossierId, emprunteur}) {
        return this.axios.post(`/dossiers/${dossierId}/emprunteurs/identite`, emprunteur)
    }

    emprunteurCharger({dossierId, emprunteurId}) {
        return this.axios.get(`/dossiers/${dossierId}/emprunteurs/${emprunteurId}/identite`)
    }

    emprunteurModifier({dossierId, emprunteur}) {
        return this.axios.put(`/dossiers/${dossierId}/emprunteurs/${emprunteur.id}/identite`, emprunteur)
    }

    emprunteurSupprimer({dossierId, emprunteurId}) {
        return this.axios.delete(`/dossiers/${dossierId}/emprunteurs/${emprunteurId}`)
    }

    personnelCharger({dossierId, emprunteurId}) {
        return this.axios.get(`/dossiers/${dossierId}/emprunteurs/${emprunteurId}/informations-personnelles`)
    }

    personnelModifier({dossierId, emprunteur}) {
        return this.axios.put(`/dossiers/${dossierId}/emprunteurs/${emprunteur.id}/informations-personnelles`, emprunteur)
    }

    coordonneCharger({dossierId, emprunteurId}) {
        return this.axios.get(`/dossiers/${dossierId}/emprunteurs/${emprunteurId}/coordonnees`)
    }

    coordonneModifier({dossierId, emprunteur}) {
        return this.axios.put(`/dossiers/${dossierId}/emprunteurs/${emprunteur.id}/coordonnees`, emprunteur)
    }

    professionnelCharger({dossierId, emprunteurId}) {
        return this.axios.get(`/dossiers/${dossierId}/emprunteurs/${emprunteurId}/informations-professionnelles`)
    }

    professionnelModifier({dossierId, emprunteur}) {
        return this.axios.put(`/dossiers/${dossierId}/emprunteurs/${emprunteur.id}/informations-professionnelles`, emprunteur)
    }

    banqueCharger({dossierId, emprunteurId}) {
        return this.axios.get(`/dossiers/${dossierId}/emprunteurs/${emprunteurId}/informations-bancaires`)
    }

    banqueModifier({dossierId, emprunteur}) {
        return this.axios.put(`/dossiers/${dossierId}/emprunteurs/${emprunteur.id}/informations-bancaires`, emprunteur)
    }

    banquesCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/banques`)
    }

    banquesModifier({dossierId, banques}) {
        return this.axios.put(`/dossiers/${dossierId}/banques`, banques)
    }

    assuranceCharger({dossierId, emprunteurId}) {
        return this.axios.get(`/dossiers/${dossierId}/emprunteurs/${emprunteurId}/assurance`)
    }

    assuranceModifier({dossierId, emprunteur}) {
        return this.axios.put(`/dossiers/${dossierId}/emprunteurs/${emprunteur.id}/assurance`, emprunteur)
    }

    bilanCharger({dossierId, bilanId}) {
        return this.axios.get(`/dossiers/${dossierId}/bilans/${bilanId}`)
    }

    bilanCreer({dossierId, bilan}) {
        return this.axios.post(`/dossiers/${dossierId}/bilans`, bilan)
    }

    bilanModifier({dossierId, bilan}) {
        return this.axios.put(`/dossiers/${dossierId}/bilans/${bilan.id}`, bilan)
    }

    bilanSupprimer({dossierId, bilanId}) {
        return this.axios.delete(`/dossiers/${dossierId}/bilans/${bilanId}`)
    }

    revenuCharger({dossierId, emprunteurId}) {
        return this.axios.get(`/dossiers/${dossierId}/emprunteurs/${emprunteurId}/revenu`)
    }

    revenuModifier({dossierId, emprunteur}) {
        return this.axios.put(`/dossiers/${dossierId}/emprunteurs/${emprunteur.id}/revenu`, emprunteur)
    }

    coutsCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/chiffrage/couts`)
    }

    coutsModifier({dossierId, couts}) {
        return this.axios.put(`/dossiers/${dossierId}/chiffrage/couts`, couts)
    }

    fraisCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/chiffrage/frais`)
    }

    fraisModifier({dossierId, frais}) {
        return this.axios.put(`/dossiers/${dossierId}/chiffrage/frais`, frais)
    }

    apportsCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/chiffrage/apports`)
    }

    apportsModifier({dossierId, apports}) {
        return this.axios.put(`/dossiers/${dossierId}/chiffrage/apports`, apports)
    }

    projetCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/projet/description`)
    }

    projetModifier({dossierId, projet}) {
        return this.axios.put(`/dossiers/${dossierId}/projet/description`, projet)
    }

    biensLister({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/biens`)
    }

    projetBienCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/projet/bien`)
    }

    projetBienModifier({dossierId, bien}) {
        return this.axios.put(`/dossiers/${dossierId}/projet/bien`, bien)
    }

    projetNotaireCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/projet/notaire`)
    }

    projetNotaireModifier({dossierId, notaire}) {
        return this.axios.put(`/dossiers/${dossierId}/projet/notaire`, notaire)
    }

    epargneCharger({dossierId, epargneId}) {
        return this.axios.get(`/dossiers/${dossierId}/epargnes/${epargneId}`)
    }

    epargneCreer({dossierId, epargne}) {
        return this.axios.post(`/dossiers/${dossierId}/epargnes`, epargne)
    }

    epargneModifier({dossierId, epargne}) {
        return this.axios.put(`/dossiers/${dossierId}/epargnes/${epargne.id}`, epargne)
    }

    epargneSupprimer({dossierId, epargneId}) {
        return this.axios.delete(`/dossiers/${dossierId}/epargnes/${epargneId}`)
    }

    bienCharger({dossierId, bienId}) {
        return this.axios.get(`/dossiers/${dossierId}/biens/${bienId}`)
    }

    bienCreer({dossierId, bien}) {
        return this.axios.post(`/dossiers/${dossierId}/biens`, bien)
    }

    bienModifier({dossierId, bien}) {
        return this.axios.put(`/dossiers/${dossierId}/biens/${bien.id}`, bien)
    }

    bienSupprimer({dossierId, bienId}) {
        return this.axios.delete(`/dossiers/${dossierId}/biens/${bienId}`)
    }

    chargesCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/charges/divers`)
    }

    chargesModifier({dossierId, charges}) {
        return this.axios.put(`/dossiers/${dossierId}/charges/divers`, charges)
    }

    creditCharger({dossierId, creditId}) {
        return this.axios.get(`/dossiers/${dossierId}/credits/${creditId}`)
    }

    creditCreer({dossierId, credit}) {
        return this.axios.post(`/dossiers/${dossierId}/credits`, credit)
    }

    creditModifier({dossierId, credit}) {
        return this.axios.put(`/dossiers/${dossierId}/credits/${credit.id}`, credit)
    }

    creditSupprimer({dossierId, creditId}) {
        return this.axios.delete(`/dossiers/${dossierId}/credits/${creditId}`)
    }

    titulairesLister({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/titulaires`)
    }

    configurationsGeneralCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/configurations/generales`)
    }

    configurationsGeneralModifier({dossierId, configurations}) {
        return this.axios.put(`/dossiers/${dossierId}/configurations/generales`, configurations)
    }

    apporteursLister() {
        return this.axios.get(`/apporteurs`)
    }

    apporteurCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/configurations/apporteur`)
    }

    apporteurModifier({dossierId, apporteur}) {
        return this.axios.put(`/dossiers/${dossierId}/configurations/apporteur`, apporteur)
    }

    apporteurAjouter({apporteur}) {
        return this.axios.post('/apporteurs', apporteur)
    }

    deviseCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/configurations/devises`)
    }

    deviseModifier({dossierId, devises}) {
        return this.axios.put(`/dossiers/${dossierId}/configurations/devises`, devises)
    }

    presentationDossierCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/configurations/presentation`)
    }

    presentationDossierModifier({dossierId, presentation}) {
        return this.axios.put(`/dossiers/${dossierId}/configurations/presentation`, presentation)
    }

    honorairesCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/configurations/honoraires`)
    }

    honorairesModifier({dossierId, honoraires}) {
        return this.axios.put(`/dossiers/${dossierId}/configurations/honoraires`, honoraires)
    }

    honorairesClientCalculer({dossierId, honoraires}) {
        return this.axios.post(`/dossiers/${dossierId}/honoraires-client`, honoraires)
    }

    honorairesClientPourcentageCalculer({dossierId, honoraires}) {
        return this.axios.post(`/dossiers/${dossierId}/honoraires-client-pourcentage`, honoraires)
    }

    commissionsMandantCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/configurations/commissions-mandant`)
    }

    commissionsMandantModifier({dossierId, commissions}) {
        return this.axios.put(`/dossiers/${dossierId}/configurations/commissions-mandant`, commissions)
    }

    commissionsLicencieCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/configurations/commissions-licencie`)
    }

    commissionsApporteurModifier({dossierId, commissions}) {
        return this.axios.put(`/dossiers/${dossierId}/configurations/commissions-apporteur`, commissions)
    }

    commissionsApporteurCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/configurations/commissions-apporteur`)
    }

    commissionsFranchiseurModifier({dossierId, commissions}) {
        return this.axios.put(`/dossiers/${dossierId}/configurations/commissions-franchiseur`, commissions)
    }

    commissionsFranchiseurCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/configurations/commissions-franchiseur`)
    }

    commissionsLicencieModifier({dossierId, commissions}) {
        return this.axios.put(`/dossiers/${dossierId}/configurations/commissions-licencie`, commissions)
    }

    retrocommissionsCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/configurations/retrocommissions`)
    }

    retrocommissionsModifier({dossierId, retrocommissions}) {
        return this.axios.put(`/dossiers/${dossierId}/configurations/retrocommissions`, retrocommissions)
    }

    retrocommissionsMontantCalculer({dossierId, retrocommissions}) {
        return this.axios.post(`/dossiers/${dossierId}/retrocommission-montant`, retrocommissions)
    }

    retrocommissionsPourcentageCalculer({dossierId, retrocommissions}) {
        return this.axios.post(`/dossiers/${dossierId}/retrocommission-pourcentage`, retrocommissions)
    }

    financementPreferencesCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/financement/preferences`)
    }

    financementPreferencesModifier({dossierId, preferences}) {
        return this.axios.put(`/dossiers/${dossierId}/financement/preferences`, preferences)
    }

    financementRecherchesCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/financement/recherches`)
    }

    financementRecherchesModifier({dossierId, recherches}) {
        return this.axios.put(`/dossiers/${dossierId}/financement/recherches`, recherches)
    }

    precisionsCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/precisions`)
    }

    precisionsModifier({dossierId, precisions}) {
        return this.axios.put(`/dossiers/${dossierId}/precisions`, precisions)
    }

    personneMoraleCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/societe/personne-morale`)
    }

    personneMoraleModifier({dossierId, personneMorale}) {
        return this.axios.put(`/dossiers/${dossierId}/societe/personne-morale`, personneMorale)
    }

    adresseCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/societe/adresse`)
    }

    adresseModifier({dossierId, adresse}) {
        return this.axios.put(`/dossiers/${dossierId}/societe/adresse`, adresse)
    }

    societeCharger({dossierId, societeId}) {
        return this.axios.get(`/dossiers/${dossierId}/societes/${societeId}`)
    }

    societeCreer({dossierId, societe}) {
        return this.axios.post(`/dossiers/${dossierId}/societes`, societe)
    }

    societeModifier({dossierId, societe}) {
        return this.axios.put(`/dossiers/${dossierId}/societes/${societe.id}`, societe)
    }

    societeSupprimer({dossierId, societeId}) {
        return this.axios.delete(`/dossiers/${dossierId}/societes/${societeId}`)
    }

    endettementLocatifCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/charges/endettement-locatif`)
    }

    endettementLocatifModifier({dossierId, endettementLocatif}) {
        return this.axios.put(`/dossiers/${dossierId}/charges/endettement-locatif`, endettementLocatif)
    }

    notairesLister() {
        return this.axios.get(`/notaires`)
    }

    piecesLister({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/configurations/pieces`)
    }

    piecesModifier({dossierId, pieces}) {
        return this.axios.put(`/dossiers/${dossierId}/configurations/pieces`, pieces)
    }

    documentsCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/configurations/documents`)
    }

    documentsModifier({dossierId, document}) {
        return this.axios.put(`/dossiers/${dossierId}/configurations/documents`, document)
    }

    configurationsCreer({dossierId, configuration}) {
        return Promise.resolve({})
    }

    rdvInitialiser({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/rendez-vous`)
    }

    rdvCreer({dossierId, rdv}) {
        return this.axios.post(`/dossiers/${dossierId}/rendez-vous`, rdv)
    }

    rdvLocaliser({dossierId, interlocuteurId}) {
        return this.axios.get(`/dossiers/${dossierId}/rendez-vous/lieu?interlocuteur_id=${interlocuteurId}`)
    }

    /* Constantes */

    // identite
    civilites() {
        return this.axios.get('/civilites')
    }

    // personnel
    situationsFamiliales() {
        return this.axios.get('/situations-familiales')
    }

    regimesMatrimoniaux() {
        return this.axios.get('/regimes-matrimoniaux')
    }

    // coordonne
    situationsImmobilieres() {
        return this.axios.get('/situations-immobilieres')
    }

    // professionnel

    categoriesProfessionnelles() {
        return this.axios.get('/categories-professionnelles')
    }

    contratTypes() {
        return this.axios.get('/contrats-types')
    }

    // credit & charges

    endettementTypes() {
        return this.axios.get('/endettement-types')
    }

    endettementLocatifTypes() {
        return this.axios.get('/endettement-locatif-types')
    }

    pretTypes() {
        return this.axios.get('/pret-types')
    }

    // epargne

    versementTypes() {
        return this.axios.get('/versement-types')
    }

    // projet

    projetTypes() {
        return this.axios.get('/projet-types')
    }

    projetUsages() {
        return this.axios.get('/projet-usages')
    }

    projetPrecisions() {
        return this.axios.get('/projet-precisions')
    }

    projetDestinations() {
        return this.axios.get('/projet-destinations')
    }

    projetAnciennete() {
        return this.axios.get('/projet-anciennete')
    }

    projetNormes() {
        return this.axios.get('/projet-normes')
    }

    projetPieces() {
        return this.axios.get('/projet-pieces')
    }

    projetDpe() {
        return this.axios.get('/projet-dpe')
    }

    garantieTypes() {
        return this.axios.get('/garantie-types')
    }

    prestationTypes() {
        return this.axios.get('/prestation-types')
    }

    sports() {
        return this.axios.get('/sports')
    }

    statutsJuridiques() {
        return this.axios.get('/statuts-juridiques')
    }

    registres() {
        return this.axios.get('/registre-types')
    }

    cautions() {
        return this.axios.get('/caution-types')
    }

    // finalisations

    retrocommissionTypes() {
        return this.axios.get('/retrocommission-types')
    }

    tauxTypes() {
        return this.axios.get('/taux-types')
    }

    devises() {
        return this.axios.get('/devises')
    }

    pieces({credit_pro}) {
        return this.axios.get(`/pieces`, {params: {credit_pro}})
    }

    interlocuteurs() {
        return Promise.resolve({
            data: [
                {id: 1, nom: 'Paul Dupont'},
                {id: 2, nom: 'John Doe'},
                {id: 3, nom: 'Jean Freddy'},
            ]
        })
    }

    retrocommissionMandantTypes() {
        return this.axios.get('/retrocommission-mandant-types')
    }

    apporteurTypes() {
        return this.axios.get('/apporteur-types')
    }

    /**
     * Menu
     */

    emprunteursMenu({dossierId, etape}) {
        return this.axios.get(`/dossiers/${dossierId}/formulaire/${etape}`)
    }

    epargnesMenu({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/formulaire/epargnes`)
    }

    biensMenu({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/formulaire/biens`)
    }

    bilansMenu({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/formulaire/bilans`)
    }

    chargesMenu({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/formulaire/charges`)
    }

    finalisationsMenu({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/formulaire/finalisations`)
    }

    projetMenu({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/formulaire/projet`)
    }

    societeMenu({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/formulaire/societe`)
    }

    societesMenu({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/formulaire/societes`)
    }

    financementMenu({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/formulaire/financement`)
    }

    chiffrageMenu({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/formulaire/chiffrage`)
    }

    parametresMenu({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/formulaire/parametres`)
    }

    banquesMenu({ dossierId }) {
        return this.axios.get(`/dossiers/${dossierId}/formulaire/banques`)
    }

    mandants() {
        return this.axios.get('/utilisateurs/compte/mandants')
    }

    dossierProvenances() {
        return this.axios.get('/dossier-provenances')
    }

    pretRelaisModes() {
        return this.axios.get('/pret-relais-modes')
    }


    /**
     * Outils de calcul
     */
    ratioPretValeurCalculer({dossierId}) {
        return this.axios.post(`/dossiers/${dossierId}/ratio-pret-valeur`)
    }

    ptzCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/ptz`)
    }

    ptzCalculer({dossierId, ptz}) {
        return this.axios.post(`/dossiers/${dossierId}/ptz`, ptz)
    }

    pretRelaisCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/pret-relais`)
    }

    pretRelaisCalculer({dossierId, pretRelais}) {
        return this.axios.post(`/dossiers/${dossierId}/pret-relais`, pretRelais)
    }

    pretRelaisModifier({dossierId, bien_id}) {
        return this.axios.put(`/dossiers/${dossierId}/pret-relais/bien`, {bien_id})
    }

    pretRelaisSupprimer({dossierId}) {
        return this.axios.delete(`/dossiers/${dossierId}/pret-relais`)
    }

    pasCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/pas`)
    }

    pasCalculer({dossierId, pas}) {
        return this.axios.post(`/dossiers/${dossierId}/pas`, pas)
    }

    pasUtiliser({dossierId, pasUtiliser}) {
        return this.axios.put(`/dossiers/${dossierId}/pas`, pasUtiliser)
    }

    fraisNotaireCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/frais-notaire`)
    }

    fraisNotaireCalculer({dossierId, fraisNotaire}) {
        return this.axios.post(`/dossiers/${dossierId}/frais-notaire`, fraisNotaire)
    }

    fraisGarantieCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/frais-garantie`)
    }

    fraisGarantieCalculer({dossierId, fraisGarantie}) {
        return this.axios.post(`/dossiers/${dossierId}/frais-garantie`, fraisGarantie)
    }

    endettementLocatifCalculer({dossierId, endettementLocatif}) {
        return this.axios.post(`/dossiers/${dossierId}/endettement-locatif`, endettementLocatif)
    }

    rachatRecalculer({dossierId}) {
        return this.axios.post(`/dossiers/${dossierId}/rachat`, {})
    }

    revenusChargesCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/revenus-charges`)
    }

    patrimoineImmoCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/biens-totaux`)
    }

    patrimoineFinancierCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/epargnes-totaux`)
    }

    pretInFineCharger({dossierId}) {
        return this.axios.get(`/dossiers/${dossierId}/pret-infine`)
    }

    pretInFineAssuranceCalculer({dossierId, pretInfine}) {
        return this.axios.post(`/dossiers/${dossierId}/pret-infine/assurance-vie`, pretInfine)
    }

    pretInFineCalculer({dossierId, pretInfine}) {
        return this.axios.post(`/dossiers/${dossierId}/pret-infine`, pretInfine)
    }

    adresseVilleChercher(recherche) {
        if (recherche.postal_code !== '') {
            return this.axios.post('/outils/adresse-recherche', recherche)
        }
        return Promise.resolve({data: []})
    }

    tauxChangeRafraichir({dossierId, devises}) {
        return this.axios.post(`/dossiers/${dossierId}/devise-taux`, devises)
    }

}

/* global VUE_APP_API_URL */
export default new Api(process.env.VUE_APP_API_URL)
