<template>   
    <div class="cadre-content background-gray"> 
        <div v-if="rafraichir">
            document importé avec succès
        </div>        
        <ul class="responsive-table">
            <div class ="cadre-document">
                <div class="scroll-container" >
                    <li class="table-row" v-for="document in listeDocument" :key="document.id">
                        <div class="col col-1" data-label="Pièce justificative">
                            {{document.lib}}
                        </div>
                        <div class="col col-2" data-label="Fichier">                                   
                            <SimpleUpload :doc="document" :show="true" :isDocCom="isDocCom"/>                                                       
                        </div>
                        
                        <div v-if="!isDocCom" class="col col-3" data-label="Statut"> 
                            <DocumentStatut :documentStatut="document.document_statut" :documentId="document.id"/>                             
                        </div>                        
                    </li>
                </div>
            </div>    
        </ul> 
        <SimpleUpload :folderId="folderId" v-if="this.titreFolder != 'Upload client'" :show="false" :isUploadClient="false" :isDocCom="isDocCom"/>
        <SimpleUpload :folderId="folderId" v-if="this.titreFolder == 'Upload client'" :show="false" :isUploadClient="true"/>                                 
    </div>
</template>

<script> 
    import SimpleUpload from '../../components/SimpleUpload.vue'
    import DocumentStatut from '../../components/DocumentStatut.vue'
    export default {
        name: "ListeDocument",
        components: { 
            SimpleUpload,
            DocumentStatut
         },
        props: {
            listeDocument:Array,
            titreFolder:String,
            folderId:Number,
            isDocCom:Boolean
        },
        data(){
            return{
                rafraichir:false
            }
        },
        created(){
            this.$root.$on('afficherDocument',(document) =>{      
                this.rafraichir=true  
                setTimeout(() => {}, "1000")
                this.rafraichir=false    
            })     
        }
     
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/colors";
   .cadre-content{
    border:10px solid   #D5E8FA;
    border-radius: 4px !important; 
    text-align:center;
   }
    .scroll-container {
        width: 100%;
        max-height:50vh;
        overflow-y: scroll;
        scroll-behavior: smooth;
        
    }
    .cadre-document {
        height: 100%;
        border-radius: 5px !important;
        background-color: #F2F3F3;
    }
    
    .fa-folder-open:before {
    content: "\f15b";
    }

    body{
  
    background-color:#2590EB;
    height:100%;
    }

    

    .responsive-table {
       
        margin-left: -38px;
    
    li {
        border-radius: 5px;
        padding: 25px 30px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
    }
    .table-row {
        background-color: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
        margin-left: 11px;
        margin-right: 11px;
        margin-top:11px;
        margin-bottom: 11px;
    }
    .col-1 {
        flex-basis: 13%;
        text-align: center;
       
    }
    .col-2 {
        flex-basis: 40%;
    
    }
    .col-3 {
        flex-basis: 20%;
        
    }
    
    @media only screen and (max-width: 767px) {
        
        li {
        display: block;
        }
       
        
    }
    
    }
    

</style>