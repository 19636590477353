import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class ChiffrageA extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'devises', cle: 'devises'}
    ]

    schema() {
        return {
            devise_id: null,
            cout_acquisition: 0,
            cout_mobilier: 0,
            cout_construction: 0,
            cout_terrain: 0,
            cout_travaux: 0,
            cout_autres: [],
            pret_relais_montant: 0
        }
    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            devise_id: {
                required: customValidations.devise_id ? required : false,
            },
            cout_acquisition: {},
            cout_mobilier: {},
            cout_construction: {},
            cout_terrain: {},
            cout_travaux: {},
            cout_autres: {
                $each: {
                    libelle: {required},
                    montant: {required}
                }
            },
            pret_relais_montant: {}
        }
    }

    customValidations(schema, meta) {
        const {dossier} = meta
        return {
            devise_id: dossier.multidevise,
            cout_mobilier: dossier.projet_type_code !== 'CPRO',
            cout_construction: dossier.projet_type_code !== 'CPRO',
            cout_terrain: dossier.projet_type_code !== 'CPRO',
            cout_travaux: dossier.projet_type_code !== 'CPRO',
            pret_relais_montant: dossier.pret_relais > 0 && dossier.pret_relais_presentation_code === 'COMPT'

        }
    }
}

export class ChiffrageB extends Formulaire {

    meta = []

    schema() {
        return {
            frais_notaire: 0,
            frais_agence: 0,
            frais_garantie: 0,
            frais_dossier: 0,
            frais_courtage: 0,
        }
    }

    validations(schema, meta) {
        return {
            frais_notaire: {},
            frais_agence: {},
            frais_garantie: {},
            frais_dossier: {},
            frais_courtage: {},
        }
    }

    customValidations(schema, meta) {
        const {dossier} = meta

        return {
            frais_notaire: dossier.projet_type_code != 'CPRO' && dossier.financement_devise.code === 'EUR',
            frais_agence: dossier.projet_type_code !== 'CPRO',
            frais_garantie_outil: dossier.projet_type_code != "CPRO" && dossier.financement_devise.code === 'EUR',
            frais_courtage: dossier.honoraires_emprunt_inclus == 1 && dossier.honoraires_client > 0
        }
    }
}

export class ChiffrageC extends Formulaire {

    meta = []

    schema() {
        return {
            apport_personnel: 0,
            ptz_montant: 0,
            pel_montant: 0,
            notaire_acompte: 0,
            autre_montant: 0,
            apport_pret_relais: 0,
            apport_autres: {
                $each: {
                    libelle: {required},
                    montant: {required}
                }
            }
        }
    }

    validations(schema, meta) {
        return {
            apport_personnel: {},
            ptz_montant: {},
            pel_montant: {},
            notaire_acompte: {},
            autre_montant: {},
            apport_pret_relais: {},
            apport_autres: {
                $each: {
                    libelle: {required},
                    montant: {required}
                }
            }
        }
    }

    customValidations(schema, meta) {
        const {dossier} = meta
        return {
            apport_pret_relais: dossier.apport_pret_relais > 0 && dossier.pret_relais > 0,
            ptz_montant: !dossier.professionnel && !['RACH', 'RENE', 'SOUL', 'CPRO', 'PERSO', 'AUTR', 'SCPI'].includes(dossier.projet_type_code) && dossier.financement_devise.code === 'EUR',
            pel_montant: dossier.projet_type_code !== 'CPRO',
            notaire_acompte: dossier.projet_type_code !== 'CPRO',
            autre_montant: dossier.projet_type_code !== 'CPRO',

        }
    }
}
