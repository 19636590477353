import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class Societe extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'statutsJuridiques', cle: 'statutsJuridiques'},
    ]

    schema () {
        return {
            id: '',
            raison_sociale: '',
            statut_juridique_id: null,
        }
    }

    validations (schema) {
        return {
            id: {},
            raison_sociale: {required},
            statut_juridique_id: {required},
        }
    }

}

export class PersonneMorale extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'statutsJuridiques', cle: 'statutsJuridiques'},
        {type: 'constantes', fonction: 'registres', cle: 'registres'},
    ]

    schema () {
        return {
            raison_sociale: '',
            siren: '',
            registre_type_id: null,
            statut_juridique_id: null,
            capital: '',
            registre_ville: '',
            personne_morale_adresse: null,
            personne_morale_ville: null,
            personne_morale_cp: null,
        }
    }

    validations (schema) {
        return {
            raison_sociale: {required},
            siren: {},
            registre_type_id: {},
            statut_juridique_id: {required},
            capital: {},
            registre_ville: {},
            personne_morale_adresse: {},
            personne_morale_ville: {},
            personne_morale_cp: {},
        }
    }
}