<template>
    <div class="etape-container">

        <div class="etape-menu"
             :class="{'menu-active': menuActive}"
        >
            <div
                class="cadre content-cadre background-gray">

                <div class="cadre-tete cadre-tete--menu">

                    <span>Patrimoine immobilier</span>

                    <router-link
                        :to="$utils.formulaireAller('bien', 'creer')"
                        tag="div"
                        class="btn btn-ajouter"
                        @click.native="$root.$emit('menuChanger')"
                    >
                        <i class="fas fa-plus-circle"></i>
                        Ajouter un bien
                    </router-link>

                </div>

                <div
                    id="menu"
                    class="cadre-content"
                >
                    <div
                        class="menu-brique"
                        v-for="element in menu.menu"
                        :key="element.id"
                    >

                        <router-link
                            tag="div"
                            :to="$utils.formulaireAller('bien', element.id)"
                            @click.native="$root.$emit('menuChanger')"
                        >
                            <a class="menu-brique-tete">
                                <div>
                                    <i class="fas fa-building avatar-icon"></i>
                                    <span v-html="element.label"></span>
                                </div>
                                <i
                                    class="fas fa-trash"
                                    @click="(event) => afficherSuppressionConfirmation(event, element)"
                                ></i>
                            </a>
                            <div class="menu-brique-contenu" v-if="element.champs">

                                <menu-kpis
                                    :kpis="element.champs"
                                    :base-route="$utils.formulaireAller('bien', element.id)"
                                ></menu-kpis>
                            </div>
                            <div class="menu-brique-pied"
                                v-if="element.meta && element.meta.requis">
                                <completion
                                    :element="element"
                                ></completion>
                            </div>
                        </router-link>

                    </div>

                    <div class="menu-brique brique-creer">
                        <router-link
                            :to="$utils.formulaireAller('bien', 'creer')"
                            @click.native="$root.$emit('menuChanger')"
                        >
                            <div>
                                <i class="fas fa-building avatar-icon"></i>
                                Nouveau bien
                            </div>
                        </router-link>
                    </div>

                </div>
            </div>
        </div>

        <div class="etape-content">
            <transition
                name="fade"
                mode="out-in"
            >
                <router-view
                    :key="$route.path"
                    :menu-continuer="menuContinuer"
                ></router-view>
            </transition>
        </div>
        <transition
            name="fade"
            mode="out-in">
            <confirm
                v-if="elementASupprimer"
                status="ask-confirm"
                confirmText="Supprimer"
                :annulerClick="() => elementASupprimer = null"
                :confirmClick="() => supprimer()"
            >
                <div>
                    Souaitez-vous vraiment supprimer <span v-html="elementASupprimer.label"></span>?
                </div>
            </confirm>
        </transition>
    </div>
</template>


<script>
    import api from '@/api'
    import { menuMixin } from '@/utils/formulaire'

    export default {
        name: 'BienMenu',
        mixins: [
            menuMixin
        ],
        props: {
            navigation: Array
        },
        data() {
            return {
                elementASupprimer: null
            }
        },
        methods: {
            charger() {
                const {dossierId} = this.$route.params
                return api.biensMenu({dossierId})
            },
            initialiser() {
                /* on redirige vers le formulaire edition si présent dans l'url */
                if (this.$route.params.id) {
                    this.$router.push(this.$utils.formulaireAller('bien', this.$route.params.id))
                    return
                }

                /* sinon on ouvre automatiquement un nouveau formulaire */
                this.$router.push(this.$utils.formulaireAller('bien', 'creer'))
            },
            supprimer() {
                api.bienSupprimer({dossierId: this.$route.params.dossierId, bienId: this.elementASupprimer.id})
                    .then(() => {
                        this.menuCharger()
                        this.elementASupprimer = null
                    })
            },
            afficherSuppressionConfirmation($event, elem) {
                $event.preventDefault()
                this.elementASupprimer = elem
            },
            menuContinuer(destination) {
                this.menuCharger().then(() => {
                    if (destination === 'enregistrerEtRediriger') {
                        this.$router.push(this.$utils.prochaineEtapeNavigationAller('bien', this.navigation))
                        return
                    }
                    this.$router.push(this.$utils.prochaineEtapeAller('bien', this.$route, this.navigation))
                })
            }
        }
    }

</script>
