<template>
    <div
        id="navigation"
    >

        <div
            class="cadre"
        >
            <div class="cadre-tete cadre-tete--menu">
                <div class="dossier-nom" :title="$dossier.label">
                    <i class="far fa-folder-open"></i>
                    <span>{{ $dossier.reference}} {{$dossier.label}} </span>
                    <div
                        class="badge"
                        v-if="$dossier.professionnel"
                    >Pro
                    </div>
                </div>
            </div>
            <div
                class="cadre-content"
            >
                <alerte
                    v-if="dossierComplet && !alerteFermee"
                    type="success"
                    :fermer="() => alerteFermee = true"
                > Votre dossier est complet
                </alerte>

                <ul class="etapes"
                    :class="{'etapes--completees': dossierComplet && !alerteFermee}">
                    <li
                        class="element"
                        :id="etape.id"
                        v-for="(etape, index) in navigationElements"
                        :key="etape.id"
                        @click="() => clickElement(etape.id)"
                    >

                        <div>
                            <router-link
                                tag="div"
                                class="etape"
                                :to="{name: etape.id}"
                                @click.native="navigateEtape()"
                            >
                                <a>
                                    <div class="list-numero">
                                        <span>{{index + 1}}</span>
                                    </div>
                                    <span>{{ etape.label }}</span>
                                    <i
                                        v-if="etapeRequise(etape)"
                                        class="far fa-check-circle"
                                        :class="{
                                            'formulaire--complete': etapeComplet(etape),
                                            'formulaire--noncomplete': !etapeComplet(etape),
                                        }"
                                    ></i>
                                </a>
                            </router-link>
                            <div class="menu-formulaires">
                                <ul>
                                    <li
                                        :id="`menu-formulaire-${formulaire.id}`"
                                        class="menu-formulaire"
                                        v-for="formulaire in etape.menu"
                                        :key="formulaire.id"
                                    >
                                        <router-link
                                            v-if="$utils.formulaireAller(etape.id, formulaire.id)"
                                            :to="$utils.formulaireAller(etape.id, formulaire.id)"
                                            @click.native="navigateMenu()"
                                        >
                                            <span v-html="formulaire.label"></span>
                                            <i
                                                v-if="formulaire.meta && formulaire.meta.requis"
                                                class="far fa-check-circle"
                                                :class="{
                                                    'formulaire--complete': formulaireComplet(formulaire),
                                                    'formulaire--noncomplete': !formulaireComplet(formulaire),
                                                }"
                                            ></i>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </li>
                </ul>
            </div>

        </div>


    </div>
</template>

<script>
  import Alerte from "./Alerte.vue";

  export default {
    components: {Alerte},
    name: 'Navigation',
    props: {
      navigationElements: Array,
      clickElement: Function,
      navigate: Function,
      dossierTerminer: Object,
    },
    data() {
      return {
        alerteFermee: false,
        dossierComplet: false,
      }
    },
    created(){
      this.$root.$on('dossierComplet', () => {
        this.dossierComplet = true
      })
    },
    methods: {
      etapeRequise(etape) {
        if (etape.meta) {
          return etape.meta.requis
        }
        if (etape.menu) {
          return etape.menu.reduce((acc, curr) => acc ? acc : curr.meta && curr.meta.requis, false)
        }
      },
      etapeComplet(etape) {
        if (etape.meta) {
          return etape.meta.complet === etape.meta.requis
        }
        return etape.menu.reduce((acc, curr) => !acc ? acc : this.formulaireComplet(curr), true)
      },
      formulaireComplet(formulaire) {
        return !formulaire.meta || formulaire.meta && formulaire.meta.complet === formulaire.meta.requis
      },
      navigateEtape(){
        this.navigate()
        this.$root.$emit('menuChanger')
      },
      navigateMenu() {
        this.navigate()
        this.$root.$emit('menuChanger', false)
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import "../assets/scss/colors";

    .cadre {
        height: 100%;
    }

    .cadre-tete {
        border-bottom: 1px solid $gray-light;
    }

    .cadre-content {
        padding: 15px 0 15px 15px;
        overflow-y: scroll;
        position: relative;
    }

    .dossier-nom {
        display: flex;
        align-items: center;
        width: 100%;

        i {
            margin-right: 5px;
        }

        span {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .etapes--completees {
        margin-top: 60px !important;
    }

</style>
