import { Coordonne, CoordonnePrecisions } from './Coordonne';
import { Personnel, PersonnelPrecisions } from './Personnel';
import { ProjetBien, ProjetDescription, ProjetNotaire, ProjetPrecisions } from './Projet';
import { ChiffrageA, ChiffrageB, ChiffrageC } from './Chiffrage';
import { FinancementPrecisions, FinancementPreferences, FinancementRecherches } from "./Financement";
import {
    CommissionsFranchiseur,
    CommissionsLicencie,
    CommissionsMandant,
    CommissionsApporteur,
    Honoraires,
    Retrocommissions
} from "./Finalisation";
import { ChargePrecisions, Credit, Divers, EndettementLocatif } from "./Charge";
import { BanquePrecisions } from "./Banque";
import { Bilan } from "./Bilan";
import { Professionnel, ProfessionnelPrecisions } from '../commun/Professionnel';
import {
    ConfigurationApporteur,
    ConfigurationDevises,
    ConfigurationGeneral,
    ConfigurationPresentationProjet,
    PiecesAFournir,
    TypeFormulaire,
} from './Configuration'
import { Epargne, EpargnePrecisions } from "./Epargne";
import { Revenu, RevenuPrecisions } from "./Revenu";
import { Partenaire } from "./Partenaire";
import { Configurations, Rdv } from "./Nouveau";
import {
    EndettementLocatif as EndettementLocatifModal,
    FraisGarantie,
    FraisNotaire,
    PAS,
    PretInFine,
    PretRelais,
    Ptz
} from "./OutilsCalcul";

export default {
    Coordonne, CoordonnePrecisions,
    Personnel, PersonnelPrecisions,
    ProjetDescription, ProjetBien, ProjetPrecisions, ProjetNotaire,
    Professionnel, ProfessionnelPrecisions,
    ChiffrageA, ChiffrageB, ChiffrageC,
    FinancementPreferences, FinancementRecherches, FinancementPrecisions,
    Honoraires, Retrocommissions, CommissionsMandant, CommissionsFranchiseur, CommissionsLicencie, CommissionsApporteur,
    Divers, Credit, ChargePrecisions, EndettementLocatif,
    BanquePrecisions,
    Bilan,
    ConfigurationApporteur, ConfigurationDevises, ConfigurationGeneral, ConfigurationPresentationProjet,
    PiecesAFournir, TypeFormulaire,
    Epargne, EpargnePrecisions,
    Revenu, RevenuPrecisions,
    Configurations, Rdv,
    Ptz, PAS, FraisNotaire, FraisGarantie, EndettementLocatifModal, PretRelais, PretInFine,
    Partenaire
}
