import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class Dossier extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'statutsJuridiques', cle: 'statutsJuridiques'},
        {type: 'constantes', fonction: 'registres', cle: 'registres'},
        {type: 'constantes', fonction: 'civilites', cle: 'civilites'},
        {type: 'donnees', fonction: 'entitesCharger', cle: 'entites'},
    ]

    schema() {
        return {
            societe: {
                raison_sociale: '',
                siren: '',
                registre_type_id: null,
                statut_juridique_id: null,
                capital: '',
                registre_ville: '',
                adresse: '',
                postal_code: '',
                ville: ''
            },
            emprunteur: {
                personne_morale: null,
                raison_sociale: '',
                nom: '',
                prenom: '',
                civilite_id: null,
                nom_jf: '',
                gerant: false,
                capital_part: 0,
                caution_code: '',
            },
            possesseur_id: null
        }
    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            societe: {
                raison_sociale: {required},
                siren: {},
                registre_type_id: {},
                statut_juridique_id: {required},
                capital: {},
                registre_ville: {},
                adresse: {},
                postal_code: {},
                ville: {}
            },
            emprunteur: {
                personne_morale: {},
                raison_sociale: {
                    required: schema.emprunteur.personne_morale ? required : false
                },
                nom: {
                    required: !schema.emprunteur.personne_morale ? required : false
                },
                prenom: {
                    required: !schema.emprunteur.personne_morale ? required : false
                },
                civilite_id: {
                    required: !schema.emprunteur.personne_morale ? required : false
                },
                nom_jf: {},
                gerant: {},
                capital_part: {},
                caution_code: {},
            },
            possesseur_id: {
                required: customValidations.possesseur_id ? required : false,
            }
        }
    }

    customValidations(schema, meta) {
        const compte = meta.compte || {}
        return {
            possesseur_id: compte.niveau === "MANDANT" || compte.niveau === "LICENCIE"
        }
    }
}
