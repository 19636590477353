import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class ProjetPrecisions extends Formulaire {
    schema() {
        return {
            projet_precisions: '',
        }
    }

    validations(schema) {
        return {
            projet_precisions: {},
        }
    }
}
