<template>
    <form
        @submit="valider"
        novalidate="true"
    >
        <div class="cadre content-cadre background-white">
            <div class="cadre-tete cadre-tete--menu">
                Emprunteur
                <formulaire-status
                    :form="$v"
                ></formulaire-status>
            </div>
            <div
                id="formulaire"
                class="cadre-content"
                v-if="formulaire"
            >
                <div
                    class="form-group no-margin"
                    v-if="formulaire"
                >
                    <label>Pré-saisir les infos concernant l'assurance emprunteur pour les banques</label>
                    <toggle-button
                        :labels="{checked: 'Oui', unchecked: 'Non'}"
                        :sync="true"
                        :width="65"
                        :height="30"
                        v-model="$v.schema.assurance_active.$model"
                    />
                </div>
                <hr />
                <div
                    id="fumeur"
                    class="form-group"
                >
                    <label> Fumeur </label>
                    <toggle-button
                        :labels="{checked: 'Oui', unchecked: 'Non'}"
                        :sync="true"
                        :width="65"
                        :height="30"
                        v-model="$v.schema.fumeur.$model"
                        :disabled="!$v.schema.assurance_active.$model"
                    />
                </div>
                <div
                    id="taille"
                    class="form-group"
                >
                    <label> Taille (cm) </label>
                    <input
                        class="form-control"
                        v-model.number="$v.schema.taille.$model"
                        :disabled="!$v.schema.assurance_active.$model"
                        autocomplete="disabled"
                    />
                </div>
                <div
                    id="poids"
                    class="form-group"
                >
                    <label> Poids (kg) </label>
                    <input
                        class="form-control"
                        v-model.number="$v.schema.poids.$model"
                        :disabled="!$v.schema.assurance_active.$model"
                        autocomplete="disabled"
                    />
                </div>
                <div
                    id="sport_ids"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.sport_ids.$invalid}"
                >
                    <label> Sports pratiqués * </label>
                    <multiselect
                        :value="$v.schema.sport_ids.$model"
                        :options="sports"
                        :select-action="(ids) => $v.schema.sport_ids.$model = ids"
                        :limit-text="(count) => `${count} sports(s) sélectionné(s)`"
                        :placeholderText="'Sports pratiqués'"
                        :disabled="!$v.schema.assurance_active.$model"
                    ></multiselect>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.sport_ids.$invalid"
                    >
                        Champs invalide
                    </div>
                </div>
                <div
                    id="professionnel_deplacements"
                    class="form-group"
                >
                    <label> Déplacements professionnels </label>
                    <toggle-button
                        :labels="{checked: 'Oui', unchecked: 'Non'}"
                        :sync="true"
                        :width="65"
                        :height="30"
                        v-model="$v.schema.professionnel_deplacements.$model"
                        :disabled="!$v.schema.assurance_active.$model"
                    />
                </div>
                <div
                    id="professionnel_hauteur"
                    class="form-group"
                >
                    <label> Travail en hauteur </label>
                    <toggle-button
                        :labels="{checked: 'Oui', unchecked: 'Non'}"
                        :sync="true"
                        :width="65"
                        :height="30"
                        v-model="$v.schema.professionnel_hauteur.$model"
                        :disabled="!$v.schema.assurance_active.$model"
                    />
                </div>
                <div
                    id="professionnel_partiel"
                    class="form-group"
                >
                    <label> Temps partiel </label>
                    <toggle-button
                        :labels="{checked: 'Oui', unchecked: 'Non'}"
                        :sync="true"
                        :width="65"
                        :height="30"
                        v-model="$v.schema.professionnel_partiel.$model"
                        :disabled="!$v.schema.assurance_active.$model"
                    />
                </div>
                <div
                    id="professionnel_manutention"
                    class="form-group"
                >
                    <label> Manutention </label>
                    <toggle-button
                        :labels="{checked: 'Oui', unchecked: 'Non'}"
                        :sync="true"
                        :width="65"
                        :height="30"
                        v-model="$v.schema.professionnel_manutention.$model"
                        :disabled="!$v.schema.assurance_active.$model"
                    />
                </div>
            </div>

            <div class="cadre-pied background-primary">
                <button
                    type="button"
                    @click="formulaireAnnuler"
                    class="btn btn-transparent cancel-button"
                >
                    Annuler
                </button>
                <button
                    type="submit"
                    class="btn btn-transparent"
                >
                    Enregistrer
                </button>
            </div>

            <transition
                name="notification-afficher">

                <notification
                    v-if="notification.afficher"
                    :content="notification.texte"
                    :type="'success'"
                ></notification>
            </transition>

            <transition
                name="notification-afficher">
                <notification
                    v-if="enregistrement.afficher"
                    :content="enregistrement.texte"
                    :type="'warning'"
                ></notification>
            </transition>
        </div>
    </form>
</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'

    export default {
        name: 'Assurance',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        beforeRouteUpdate(to, from, next){
            this.scrollTo(to)
            next()
        },
        validations () {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema) : {}
            }
        },
        data () {
            return {
                formulaire: null,
                schema : {}
            }
        },
        computed: {
            sports() {
              return this.formulaire.constantes.sports.filter(s => s.code !== 'CAT')
            }
        },
        created () {
            const {dossierId, id} = this.$route.params

            this.$formulaire.charger('particulier', 'Assurance')
                .then((formulaire) => {
                    this.formulaire = formulaire
                    this.schema = formulaire.schema()

                    if (id)
                        api.assuranceCharger({dossierId, emprunteurId: id})
                            .then(d => {
                                this.schema = d.data
                            })
                })
        },
        methods: {
            enregistrer () {
                return api.assuranceModifier({dossierId: this.$route.params.dossierId, emprunteur: this.$v.schema.$model})
                    .then(() => {
                        this.notificationAfficher().then(() => {
                            this.menuContinuer()
                        })
                    })
            }
        }
    }
</script>
