<template>
    <div
        class="formulaire-status"
        v-if="statusAfficher"
    >
        <i class="fas fa-exclamation-triangle"></i>
        <div>Données non sauvegardées</div>
    </div>
</template>

<script>
    export default {
        name: 'FormulaireStatus',
        props: {
            form: Object
        },
        computed: {
            statusAfficher() {
                return this.form.$anyDirty
            }
        },
        methods: {}
    }
</script>

<style lang="scss" scoped>
    @import "../assets/scss/colors";

    .formulaire-status {
        text-align: center;
        color: $orange;
        font-size: .7rem;
        text-transform: none;
    }
</style>
