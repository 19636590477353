<template>
    <div class="etape-container">

        <div class="etape-menu"
             :class="{'menu-active': menuActive}">
            <div
                class="cadre content-cadre background-gray">

                <div class="cadre-tete cadre-tete--menu">

                    <span>Charges et crédits</span>

                    <router-link
                        :to="$utils.formulaireAller('charge', 'creer')"
                        tag="div"
                        class="btn btn-ajouter"
                        @click.native="$root.$emit('menuChanger')"
                    >
                        <i class="fas fa-plus-circle"></i>
                        Ajouter un crédit
                    </router-link>

                </div>

                <div
                    id="menu"
                    class="cadre-content"
                >
                    <div
                        class="menu-brique"
                        v-for="element in menu.menu"
                        :key="element.id"
                    >

                        <router-link
                            tag="div"
                            v-if="element.id !== 'endettement'"
                            :to="$utils.formulaireAller('charge', element.id)"
                            @click.native="$root.$emit('menuChanger')"
                        >

                            <a class="menu-brique-tete">
                                <div>
                                    <span v-html="element.label"></span>
                                </div>
                                <i
                                    class="fas fa-trash"
                                    v-if="element.id !== 'endettement' && element.id !== 'divers'"
                                    @click="(event) => afficherSuppressionConfirmation(event, element)"
                                ></i>
                            </a>
                            <div
                                class="menu-brique-contenu"
                                v-if="element.champs && element.champs.length"
                            >
                                <menu-kpis
                                    :kpis="element.champs"
                                    :base-route="$utils.formulaireAller('charge', element.id)"
                                ></menu-kpis>
                            </div>
                            <div class="menu-brique-pied"
                                v-if="element.meta && element.meta.requis">
                                <completion
                                    :element="element"
                                ></completion>
                            </div>
                        </router-link>

                    </div>

                    <div class="menu-brique brique-creer">
                        <router-link
                            :to="$utils.formulaireAller('charge', 'creer')"
                            @click.native="$root.$emit('menuChanger')"
                        >
                            Nouveau crédit
                        </router-link>
                    </div>

                </div>

            </div>
        </div>

        <div class="etape-content">
            <transition
                name="fade"
                mode="out-in"
            >
                <router-view
                    :key="$route.path"
                    :menu-continuer="menuContinuer"
                ></router-view>
            </transition>
        </div>
        <transition
            name="fade"
            mode="out-in">
            <confirm
                v-if="elementASupprimer"
                status="ask-confirm"
                confirmText="Supprimer"
                :annulerClick="() => elementASupprimer = null"
                :confirmClick="() => supprimer()"
            >
                <div>
                    Souaitez-vous vraiment supprimer <span v-html="elementASupprimer.label"></span>?
                </div>
            </confirm>
        </transition>
    </div>
</template>


<script>
    import api from '@/api'
    import { menuMixin } from '@/utils/formulaire'

    export default {
        name: 'ChargeMenu',
        mixins: [
            menuMixin
        ],
        props: {
            navigation: Array
        },
        data() {
            return {
                elementASupprimer: null
            }
        },
        methods: {
            charger() {
                const {dossierId} = this.$route.params
                return api.chargesMenu({dossierId})
            },
            initialiser() {
                this.$router.push(this.$utils.formulaireAller('charge', 'divers'))
            },
            supprimer() {
                const {dossierId} = this.$route.params
                api.creditSupprimer({dossierId, creditId: this.elementASupprimer.id}).then(() => {
                    this.menuCharger()
                    this.$root.$emit('navigationCharger')
                    this.elementASupprimer = null
                })
            },
            afficherSuppressionConfirmation($event, elem) {
                $event.preventDefault()
                this.elementASupprimer = elem
            },
            menuContinuer(destination) {
                this.menuCharger().then(() => {
                    if (destination === 'enregistrerEtRediriger') {
                        this.$router.push(this.$utils.prochaineEtapeNavigationAller('charge', this.navigation))
                        return
                    }
                    this.$router.push(this.$utils.prochaineEtapeAller('charge', this.$route, this.navigation))
                })
            }
        }
    }

</script>
