import PersonnelMenu from '@/views/commun/personnel/Menu'
import Personnel from '@/views/commun/personnel/formulaires/Personnel'

import CoordonneMenu from '@/views/commun/coordonne/Menu'
import Coordonne from '@/views/commun/coordonne/formulaires/Coordonne'

import ProfesssionnelMenu from '@/views/commun/professionnel/Menu'
import Professionnel from '@/views/commun/professionnel/formulaires/Professionnel'

import RevenuMenu from '@/views/commun/revenu/Menu'
import Revenu from '@/views/commun/revenu/formulaires/Revenu'

import EpargneMenu from '@/views/commun/epargne/Menu'
import Epargne from '@/views/commun/epargne/formulaires/Epargne'

import BienMenu from '@/views/commun/bien/Menu'
import Bien from '@/views/commun/bien/formulaires/Bien'

import IdentiteMenu from '@/views/commun/identite/Menu'
import Identite from '@/views/professionnel/identite/formulaires/Identite'

import PeronneMorale from '@/views/professionnel/societe/formulaires/PersonneMorale'
import SocieteMenu from '@/views/professionnel/societe/Menu'

import SocieteModifier from '@/views/professionnel/societes/formulaires/Societe'
import SocietesMenu from '@/views/professionnel/societes/Menu'

import AssuranceMenu from '@/views/particulier/assurance/Menu'
import Assurance from '@/views/particulier/assurance/formulaires/Assurance'

import BanqueMenu from '@/views/professionnel/banque/Menu'
import Banque from '@/views/professionnel/banque/formulaires/Banque'

/*import BilanMenu from '@/views/professionnel/bilan/Menu'
import Bilan from '@/views/commun/bilan/formulaires/Bilan'*/

import PresentationProjet from '@/views/commun/parametres/formulaires/PresentationProjet'

import ChargeMenu from '@/views/commun/charge/Menu'
import Divers from '@/views/commun/charge/formulaires/Divers'
import Credit from '@/views/commun/charge/formulaires/Credit'
import EndettementLocatif from '@/views/commun/charge/formulaires/EndettementLocatif'


export default [
    {
        path: 'societe',
        name: 'societe',
        component: SocieteMenu,
        children: [
            {
                path: 'personne-morale',
                name: 'personneMoraleModifier',
                component: PeronneMorale
            },
            {
                path: 'presentation',
                name: 'presentationDossierModifier',
                component: PresentationProjet
            }
        ]
    },
    {
        path: 'societes',
        name: 'societes',
        component: SocietesMenu,
        children: [
            /*{
                path: 'creer',
                name: 'societeCreer',
                component: SocieteModifier
            },*/
            {
                path: ':id',
                name: 'societeModifier',
                component: SocieteModifier
            }
        ]
    },
    {
        path: 'identite',
        name: 'identite',
        component: IdentiteMenu,
        //redirect: {name: 'identiteCreer'},
        children: [
            /*{
                path: 'creer',
                name: 'identiteCreer',
                component: Identite,
            },*/
            {
                path: ':id',
                name: 'identiteModifier',
                component: Identite,
            }
        ]
    },
    {
        path: 'personnel',
        name: 'personnel',
        component: PersonnelMenu,
        children: [
            {
                path: ':id',
                name: 'personnelModifier',
                component: Personnel,
            }
        ]
    },
    {
        path: 'coordonne',
        name: 'coordonne',
        component: CoordonneMenu,
        children: [
            {
                path: ':id',
                name: 'coordonneModifier',
                component: Coordonne,
            }
        ]
    },
    {
        path: 'professionnel',
        name: 'professionnel',
        component: ProfesssionnelMenu,
        children: [
            {
                path: ':id',
                name: 'professionnelModifier',
                component: Professionnel,
            }
        ]
    },
    {
        path: 'revenu',
        name: 'revenu',
        component: RevenuMenu,
        children: [
            {
                path: ':id',
                name: 'revenuModifier',
                component: Revenu,
            },
        ]
    },
    {
        path: 'epargne',
        name: 'epargne',
        component: EpargneMenu,
        children: [
            {
                path: 'creer',
                name: 'epargneCreer',
                component: Epargne,
            },
            {
                path: ':id',
                name: 'epargneModifier',
                component: Epargne,
            },
        ]
    },
    {
        path: 'bien',
        name: 'bien',
        component: BienMenu,
        children: [
            {
                path: 'creer',
                name: 'bienCreer',
                component: Bien,
            },
            {
                path: ':id',
                name: 'bienModifier',
                component: Bien,
            },
        ]
    },
    {
        path: 'assurance',
        name: 'assurance',
        component: AssuranceMenu,
        children: [
            {
                path: ':id',
                name: 'assuranceModifier',
                component: Assurance,
            },
        ]
    },
    {
        path: 'banque',
        name: 'banque',
        component: BanqueMenu,
        children: [
            {
                path: ':id',
                name: 'banqueModifier',
                component: Banque,
            },
        ]
    },
  {
    path: 'charge',
    name: 'charge',
    component: ChargeMenu,
    children: [
      {
        path: 'divers',
        name: 'diversModifier',
        component: Divers,
      },
      {
        path: 'endettement',
        name: 'endettementLocatifModifier',
        component: EndettementLocatif,
      },
      {
        path: 'creer',
        name: 'creditCreer',
        component: Credit,
      },
      {
        path: ':id',
        name: 'creditModifier',
        component: Credit,
      },
    ]
  },
    /*{
        path: 'bilan',
        name: 'bilan',
        component: BilanMenu,
        children: [
            {
                path: 'creer',
                name: 'bilanCreer',
                component: Bilan
            },
            {
                path: ':id',
                name: 'bilanModifier',
                component: Bilan,
            },
        ]
    },*/
]
