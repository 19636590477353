<template>
    <div class="cont">
        <div class="actions-container">
            <i
                @click="appQuitter"
                class="fas fa-times fa-2x"
            ></i>
        </div>
        <div class="title" >
            Documents de mon Courtier
        </div>
    
        <div >               
            <ListeDocument  
                :listeDocument ="listeDocument" 
                :titreFolder="''"
                :isDocCom="true"  
                >
            </ListeDocument>                                             
        </div>
        <transition name = "fade">
            <div v-if="ouvrirWebWiewer">
                <ModalEspaceClient
                        :fichierUrl="fichierUrl"    
                        >
                </ModalEspaceClient>
            </div>
        </transition>
    </div>
</template>

<script>
    import api from '@/api'
    import ListeDocument from '@/views/piecesJustificatives/ListeDocument.vue'
    import ModalEspaceClient from '../../components/ModalEspaceClient.vue' 
    export default {
        name:'DocumentsCommerciauxEspace',
        components:{
            ListeDocument,
            ModalEspaceClient
        },
        data(){                
            return{
                listeDocument:null,
                fichierUrl:"",
                ouvrirWebWiewer:false                       
            }
        },
        created(){
            //API
            api.documentsCommerciauxAfficher(this.$route.params.dossierId)
            .then( (response) => {
                this.listeDocument = response.data    
            }),
            //EVENT
            //évenement reçu par le composant SimpleUpload
            this.$root.$on('ouvrirWebViewer',(fichierUrl,afficher)=>{
                this.fichierUrl = fichierUrl
                if(afficher == true){
                    this.ouvrirWebWiewer=true
                }        
            }),
            //
            this.$root.$on('fermerWebViewer',()=>{
                this.fichierUrl = ""
                this.ouvrirWebWiewer=false
            })
           
        },
        methods:{   
            //méthode appeler par la buton pour fermer l'application
             appQuitter(){
                window.location.href = '/'
                this.btnReturn.click()
            }
            
        }
    }
</script>


<style lang="scss" scoped>
 @import "../../assets/scss/colors";

    .title {
    position: relative;
    color: $primary;
    border-bottom: 1px solid $primary;
    padding: 15px 8px;
    text-align: center;
    }
    .cont {
        padding: 20px;
    overflow-y: scroll;
    overflow-x: unset;
    height: 100% !important;

    &.cadre-content-principal {
      padding: 10px;
    }
       
    }
    .cadre-folder {
        max-width: 400px;
        margin: left;
    }
    .menu-brique a {
        border-radius: 0;
    }

    .cadre-pied {
        margin-left: auto;
    }
    
    @media (max-width: 350px) {
     .piece-conteneur{
        flex-direction: column;
     }    
    }
    @media (min-width: 351px) {
     .piece-conteneur{
        flex-direction: row;
     }
    
    }
    .actions-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 10px;
    } 
    .modalWebWiew {
        position:absolute;
        top:15px;
        width: 100%;
    }
    .fade-enter-active,
    .fade-leave-active {
        transition:  all  1s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    
    }
    
         
</style>