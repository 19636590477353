<template>
    <div class="cadre-conteneur cadre">

        <label for="nav">
            <i class="fas fa-bars fa-2x"></i>
        </label>
        <div class="actions-container">
            <i
                @click="appQuitter"
                class="fas fa-times fa-2x"
            ></i>
        </div>
        <input type="checkbox"
               id="nav"
               v-model="navOpened"
        />
        <div class="background"
             @click="navOpened = false"
        ></div>

        <div class="cadre-content cadre-content-principal background-gray">
            <div class="dossier-conteneur">
                <div class="dossier-content">
                    <div class="cadre-left">
                        <Navigation
                            class="navigation"
                            :navigation-elements="navigation"
                            :clickElement="clickNavigationElement"
                            :navigate="navigate"
                        >
                        </Navigation>

                        <div class="menu-contextuel"
                             v-if="$utils.outilsDeCalculAfficher($route.path) && outilsCalculsAfficher()"
                        >
                            <div
                                class="menu-en-tete"
                            >
                                Outils de calcul
                            </div>
                            <div class="cadre-content">
                                <ul class="list-unstyled">
                                    <li
                                        class="text-primary cursor-pointer"
                                        :class="{disabled: crdIraDesactiver}"
                                        @click="confirmCalculIra = true"
                                    >
                                        <i class="fas fa-sync-alt"></i>
                                        {{crdIraText}}
                                    </li>
                                    <li
                                        class="text-primary cursor-pointer"
                                        :class="{disabled: pretRelaisDesactiver}"
                                        v-if="!$dossier.professionnel || $dossier.societe.statut_juridique_code === 'SCI'"
                                        @click="modalPretRelaisVisible = true; modalQuitConfirmVisible = false"
                                    >
                                        <i class="fas fa-sync-alt"></i>
                                        Prêt relais
                                    </li>
                                    <li
                                        class="text-primary cursor-pointer"
                                        :class="{disabled: $dossier.pret_relais === '0'}"
                                        v-if="!$dossier.professionnel || $dossier.societe.statut_juridique_code === 'SCI'"
                                        @click="modalSupprimerPretRelaisVisible = true;"
                                    >
                                        <i class="fas fa-sync-alt"></i>
                                        Supprimer le Prêt relais
                                    </li>
                                    <li
                                        class="text-primary cursor-pointer"
                                        :class="{disabled: pasDesactiver}"
                                        v-if="!$dossier.professionnel && $dossier.financement_devise.code === 'EUR'"
                                        @click="modalPASVisible = true; modalQuitConfirmVisible = false"
                                    >
                                        <i class="fas fa-sync-alt"></i>
                                        Prêt à l'accession sociale
                                    </li>
                                    <li
                                        class="text-primary cursor-pointer"
                                        :class="{disabled: pretInfineDesactiver}"
                                        v-if="!$dossier.professionnel || $dossier.societe.statut_juridique_code === 'SCI'"
                                        @click="modalPretInfineVisible = true"
                                    >
                                        <i class="fas fa-sync-alt"></i>
                                        Prêt in fine
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="menu-contextuel menu-contextuel--revenu"
                             v-if="$utils.metriquesAfficher($route.path, $dossier) === 'revenu' || $utils.metriquesAfficher($route.path, $dossier) === 'charge'"
                        >
                            <div class="menu-en-tete">
                                Endettement mensuel avant projet
                            </div>
                            <div class="cadre-content metriques">
                                <ul>
                                    <li>
                                        Total Revenus
                                    </li>
                                    <li>
                                        Total Charges
                                    </li>
                                    <li>
                                        Reste à vivre
                                    </li>
                                    <li>
                                        Taux d'endettement
                                    </li>
                                </ul>
                                <ul class="values">
                                    <li>
                                        <div>
                                            <vue-numeric
                                                currency=""
                                                thousand-separator=" "
                                                :precision="2"
                                                :value="metriques.revenus || 0"
                                                :read-only="true"
                                            />
                                            <span style="margin-left: 5px" v-html="metriques.devise"></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <vue-numeric
                                                currency=""
                                                thousand-separator=" "
                                                :precision="2"
                                                :value="metriques.charges || 0"
                                                :read-only="true"
                                            />
                                            <span style="margin-left: 5px" v-html="metriques.devise"></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <vue-numeric
                                                currency=""
                                                thousand-separator=" "
                                                :precision="2"
                                                :value="metriques.reste_a_vivre || 0"
                                                :read-only="true"
                                            />
                                            <span style="margin-left: 5px" v-html="metriques.devise"></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <vue-numeric
                                                currency=""
                                                thousand-separator=" "
                                                :precision="2"
                                                :value="metriques.taux || 0"
                                                :read-only="true"
                                            />
                                            %
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="menu-contextuel menu-contextuel--bien"
                             v-if="$utils.metriquesAfficher($route.path, $dossier) === 'bien'"
                        >
                            <div class="menu-en-tete">
                                Patrimoine immobilier
                            </div>
                            <div class="cadre-content metriques">
                                <ul>
                                    <li>
                                        Total
                                    </li>
                                </ul>
                                <ul class="values">
                                    <li>
                                        <div>
                                            <vue-numeric
                                                currency=""
                                                thousand-separator=" "
                                                :precision="2"
                                                :value="metriques.total || 0"
                                                :read-only="true"
                                            />
                                            {{ $compte.societe_devise }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="menu-contextuel menu-contextuel--epargne"
                             v-if="$utils.metriquesAfficher($route.path, $dossier) === 'epargne'"
                        >
                            <div class="menu-en-tete">
                                Patrimoine financier
                            </div>
                            <div class="cadre-content metriques">
                                <ul>
                                    <li>
                                        Total
                                    </li>
                                </ul>
                                <ul class="values">
                                    <li>
                                        <div>
                                            <vue-numeric
                                                currency=""
                                                thousand-separator=" "
                                                :precision="2"
                                                :value="metriques.total || 0"
                                                :read-only="true"
                                            />
                                            {{ $compte.societe_devise }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <transition name="fade"
                                mode="out-in"
                    >
                        <router-view
                            class="router-content"
                            :navigation="navigation"
                        ></router-view>
                    </transition>
                </div>
                <transition name="modal-appear">
                    <modal
                        v-if="modalPTZVisible"
                        :close="() => modalPTZVisible = false"
                    >
                        <Ptz
                            :calculer="PTZCalculer"
                            :annuler="(e) => annulerOutilCalcul(e, 'modalPTZVisible')"
                        ></Ptz>
                    </modal>
                </transition>
                <transition name="modal-appear">
                    <modal
                        v-if="modalPretRelaisVisible"
                        :close="() => modalPretRelaisVisible = false"
                    >
                        <PretRelais
                            :calculer="pretRelaisCalculer"
                            :annuler="(e) => annulerOutilCalcul(e, 'modalPretRelaisVisible')"
                        ></PretRelais>
                    </modal>
                </transition>
                <transition name="modal-appear">
                    <modal
                        v-if="modalPASVisible"
                        :close="() => modalPASVisible = false"
                    >
                        <Pas
                            :calculer="PASCalculer"
                            :annuler="(e) => annulerOutilCalcul(e, 'modalPASVisible')"
                        ></Pas>
                    </modal>
                </transition>
                <transition name="modal-appear">
                    <modal
                        v-if="modalPretInfineVisible"
                        :close="() => modalPretInfineVisible = false"
                    >
                        <PretInFine
                            :calculer="pretInfineCalculer"
                            :annuler="(e) => annulerOutilCalcul(e, 'modalPretInfineVisible')"
                        ></PretInFine>
                    </modal>
                </transition>
                <transition name="modal-appear">
                    <confirm
                        v-if="modalQuitConfirmVisible"
                        status="ask-confirm"
                        confirm-text="Enregistrer"
                        annuler-text="Abandonner"
                        :annuler-click="annulerModalQuit"
                        :confirm-click="confirmerModalQuit"
                    >Voulez-vous enregistrer vos modifications avant de quitter ce formulaire ?
                    </confirm>
                </transition>
                <transition name="modal-appear">
                    <confirm
                        v-if="confirmCalculIra"
                        status="ask-confirm"
                        confirm-text="Calculer"
                        annuler-text="Abandonner"
                        :annuler-click="() => confirmCalculIra = false"
                        :confirm-click="crdIraClickConfirm"
                    >Les CRD & IRA vont être recalculés
                    </confirm>
                </transition>
                <transition name="modal-appear">
                    <confirm
                        v-if="modalSupprimerPretRelaisVisible"
                        status="ask-confirm"
                        confirm-text="Supprimer"
                        annuler-text="Annuler"
                        :annuler-click="() => modalSupprimerPretRelaisVisible = false"
                        :confirm-click="supprimerPretRelais"
                    >Les informations du prêt relais seront reinitialisées
                    </confirm>
                </transition>
                <!-- <transition
                    name="notification-afficher">

                    <notification
                        v-if="notification.afficher"
                        :content="notification.texte"
                        :type="'success'"
                    ></notification>

                    <notification
                        v-if="enregistrement"
                        :content="enregistrement_texte"
                        :type="'success'"
                    ></notification>
                </transition> -->

            </div>

            <transition
                name="notification-afficher">
                <alerte
                    v-if="alerteBienAffiche"
                    type="info"
                    :fermer="() => alerteBienAffiche = false"
                >
                    <ul>
                        <li v-for="info in alertBienInformations">
                            {{info}}
                        </li>
                    </ul>
                </alerte>
            </transition>

            <transition name="modal-appear">
                <modal
                    v-if="dossierTerminer"
                    :close="saisiePoursuivre"
                >
                    <template>
                        <div>
                            <div class="modal-content">
                                <div class="cadre">
                                    <div class="cadre-tete cadre-tete--menu">
                                        Dossier : informations requises complètes
                                    </div>
                                    <div
                                        class="cadre-content"
                                    >
                                        <p>
                                            Merci pour la saisie de vos informations, toutes les étapes ont été parcourues.
                                        </p>
                                        <br>
                                        <p>Vous pouvez continuer de compléter les étapes ou terminer votre saisie.</p>

                                    </div>
                                </div>
                                <div class="cadre-pied background-primary">
                                    <button
                                        class="btn btn-transparent"
                                        @click="appQuitter()"
                                    >
                                        Fermer
                                    </button>
                                    <button
                                        class="btn btn-transparent"
                                        @click="saisiePoursuivre"
                                    >
                                        Poursuivre la saisie
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>
                </modal>
            </transition>

            <div class="chargement" v-if="enregistrement.afficher">
                <div>
                    <b> Enregistrement en cours... </b>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import Dropdown from '@/components/Dropdown'
  import Navigation from '@/components/Navigation.vue'
  import api from '@/api'
  import Ptz from '@/views/commun/outils-calcul/formulaires/Ptz.vue'
  import Pas from '@/views/commun/outils-calcul/formulaires/Pas.vue'
  import PretRelais from '@/views/commun/outils-calcul/formulaires/PretRelais.vue'
  import PretInFine from '@/views/commun/outils-calcul/formulaires/PretInFine.vue'
  import { resetRouter } from '@/router'
  import Alerte from "../components/Alerte.vue";

  export default {
    name: 'Dossier',
    props: {
      dossierCharge: Function
    },
    components: {
      Alerte,
      Ptz,
      Pas,
      PretRelais,
      PretInFine,
      Navigation,
      Dropdown
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        // On ne rappelle pas l'API si le dossier est déjà chargé
        let dossierPromise = Promise.resolve(vm.$dossier)
        if (to.params.dossierId !== vm.$dossier.id) {
          dossierPromise = api.dossierCharger({dossierId: to.params.dossierId}).then(d => d.data)
        }

        Promise.all([
          dossierPromise,
          api.compteCharger().then((r) => r.data)
        ]).then(([dossier, compte]) => vm.formulaireInitialiser(dossier, compte))
      })
    },
    data() {
      return {
        navigation: [],
        modalPTZVisible: false,
        modalPASVisible: false,
        confirmVisible: false,
        menuAffiche: false,
        modalQuitConfirmVisible: false,
        modalQuitCallbacks: {},
        confirmCalculIra: false,
        notification: {
          afficher: false,
        },
        modalPretRelaisVisible: false,
        modalSupprimerPretRelaisVisible: false,
        metriques: {},
        modalPretInfineVisible: false,
        alerteBienAffiche: false,
        alertBienInformations: '',
        navOpened: false,
        dossierTerminer: null,
        enregistrement: {
          afficher: false,
          texte: 'Enregistrement en cours'
        }
      }
    },
    created: function () {
      this.$root.$on('quitForm', (callbacks) => {
        this.modalQuitConfirmVisible = true
        this.modalQuitCallbacks = callbacks
      })

      this.$root.$on('outilCalculAfficher', (outilNom) => {
        this[`modal${outilNom}Visible`] = true
      })

      this.$root.$on('dossierRafraichir', (cb) => {
        this.dossierRafraichir(cb)
      })

      /*this.$root.$on('formulaireCharge', () => {
        this.dossierChargement = false
      })*/

      this.$root.$on('patrimoineFinancierRafraichir', () => {
        this.patrimoineFinancierRafraichir()
      })

      this.$root.$on('alertAfficher', (d) => {
        setTimeout(() => {
          this.alertBienInformations = d.info.informations
          this.alerteBienAffiche = true
        }, 2000)
      })

      this.$root.$on('dossierCompletVerifier', this.dossierCompletVerifier)

      this.$root.$on('enregistrementAfficher', (show) => {
        this.enregistrement.afficher = show
      })
    },
    computed: {
      pasDesactiver() {
        return ['RACH', 'RENE', 'SOUL', 'CPRO', 'PERSO', 'AUTR', 'SCPI'].includes(this.$dossier.projet_type_code)
      },
      crdIraDesactiver() {
        return !['RACH', 'RENE', 'SOUL'].includes(this.$dossier.projet_type_code)
      },
      pretRelaisDesactiver() {
        return ['RACH', 'RENE', 'SOUL', 'CPRO', 'PERSO', 'AUTRE'].includes(this.$dossier.projet_type_code)
          && !this.$dossier.societe.statut_juridique_code !== 'SCI'
      },
      pretInfineDesactiver() {
        return ['RACH', 'RENE', 'SOUL', 'CPRO', 'PERSO', 'AUTRE'].includes(this.$dossier.projet_type_code)
          && !this.$dossier.societe.statut_juridique_code !== 'SCI'
      },
      crdIraText() {
        if (this.$dossier.projet_type_code === 'SOUL') {
          return "Recalculer le CRD, les IRA & la Soulte"
        }
        return "Recalculer le CRD & les IRA"
      }
    },
    methods: {
      dossierCompletVerifier(){
        // On affiche la modal seulement si on ne l'a pas eu
        if(this.dossierTerminer !== null){
          return
        }

        const flatten = (arr) => [].concat(...arr)

        const etapesMeta = flatten(this.navigation
          .map(n => n.menu.map(m => m.meta)))
          .filter(e => e)

        const etapesRequisesTotal = etapesMeta.reduce((acc, curr) => acc + curr.requis, 0)
        const etapesCompletesTotal = etapesMeta.reduce((acc, curr) => acc + curr.complet, 0)

        if(etapesRequisesTotal === etapesCompletesTotal){
            this.dossierTerminer = true
        }
      },
      formulaireInitialiser(dossier, compte) {
        this.dossierCharge(true)
        this.$compte.champsModifier(compte)

        if (!this.$dossier.id || dossier.id !== this.$dossier.id) {
          this.$dossier.champsModifier(dossier)
        }


        api.navigationCharger({dossierId: this.$dossierId})
          .then((r) => {
            this.navigation = this.navigationFiltrer(r.data)
            this.dossierCompletVerifier()
            this.formulaireContinuer()
          })

        this.$root.$on('navigationCharger', this.formulaireRecharger)
        /*this.$root.$on('revenusChargesRafraichir', this.revenusChargesRafraichir)
        this.$root.$on('patrimoineImmoRafraichir', this.patrimoineImmoRafraichir)
        this.$root.$on('patrimoineFinancierRafraichir', this.patrimoineFinancierRafraichir)*/
      },
      navigationFiltrer(navigation){
        //Temporaire pour le fonctionnement de l'espace client, on filtre les étapes de navigation côté front
        const etapesClient = ['identite', 'personnel', 'coordonne',
          'professionnel', 'banque', 'assurance',
          'bien', 'epargne', 'revenu', 'charge'
        ]

        return navigation
          .filter(n => etapesClient.indexOf(n.id) > -1)
          .map(n => {
            n['menu'] = n['menu'].filter(m => m.id !== 'precisions' && m.id !== 'endettement')
            return n
          })
      },
      formulaireRecharger() {
        api.dossierCharger({dossierId: this.$dossier.id})
          .then((r) => {
            this.$dossier.champsModifier(r.data)
          })

        api.navigationCharger({dossierId: this.$dossier.id})
          .then((r) => {
            this.navigation = this.navigationFiltrer(r.data)
            this.dossierCompletVerifier()
          })
      },
      /* ouvre la première étape incomplète */
      formulaireContinuer() {
        this.$router.push({name: 'identiteModifier', params: { id: 'emprunteur' }})
        return;
        //Si dossier particulier et nouveau dossier
        /*if (!this.$dossier.professionnel
          && this.$route.query.nouveau) {
          this.$router.push({name: 'identiteCreer'})
          return
        }*/

        //Sinon, on redirige vers le premier formulaire requis mais non complété
        for (let i = 0; i < this.navigation.length; i++) {
          const navigation = this.navigation[i]
          for (let j = 0; j < navigation.menu.length; j++) {
            const menu = navigation.menu[j]
            if (menu.meta && menu.meta.requis > 0 && menu.meta.requis !== menu.meta.complet) {
              this.$router.push({name: navigation.id})
              return;
            }
          }
        }

        // si dossier complet on redirige vers le premier formulaire
        /*if (this.$dossier.professionnel)
          this.$router.push({name: 'societe'})
        else
            this.$router.push({name: 'identiteCreer'})*/
      },
      PTZCalculer(ptzDonnees) {
        this.modalPTZVisible = false
        if (ptzDonnees.succes) {
          this.$root.$emit('ptzDonnees', ptzDonnees)
        }
      },
      PASCalculer(ptaDonnees) {
        this.modalPASVisible = false
        if (ptaDonnees.succes) {
          this.$root.$emit('ptaDonnees', ptaDonnees)
        }
      },
      selectOption(e) {
        this.menuAffiche = false
        this.$router.push({name: e.id})
      },
      annulerModalQuit() {
        this.modalQuitCallbacks.annuler();
        this.modalQuitConfirmVisible = false;
      },
      confirmerModalQuit() {
        this.modalQuitCallbacks.confirmer();
        this.modalQuitConfirmVisible = false;
      },
      annulerOutilCalcul(e, modal) {
        e.preventDefault()
        this[modal] = false
      },
      crdIraClickConfirm() {
        const {dossierId} = this.$route.params
        api.rachatRecalculer({dossierId}).then(() => {
          this.confirmCalculIra = false
          this.$root.$emit('formulaireRafraichir')
          this.notification = Object.assign({}, this.notification, {
            texte: 'Le CRD, IRA ont bien été recalculés',
            afficher: true
          })
          setTimeout(() => this.notification = Object.assign({}, this.notification, {
            afficher: false
          }), 2000)
        })
      },
      pretRelaisCalculer() {
        this.dossierRafraichir(() => {
          this.modalPretRelaisVisible = false
          this.$router.push({name: 'avancesEtDeductions'})
        });
      },
      supprimerPretRelais() {
        const dossierId = this.$dossier.id
        api.pretRelaisSupprimer({dossierId})
          .then(() => api.dossierCharger({dossierId}))
          .then((r) => this.$dossier.champsModifier(r.data))
          .then(() => this.$root.$emit('formulaireRafraichir'))
          .then(() => this.modalSupprimerPretRelaisVisible = false)
      },
      clickNavigationElement(id) {
        if (id === 'revenu' || id === 'charge') {
          this.revenusChargesRafraichir()
        }
        if (id === 'bien') {
          this.patrimoineImmoRafraichir()
        }
        if (id === 'epargne') {
          this.patrimoineFinancierRafraichir()
        }
      },
      revenusChargesRafraichir() {
        api.revenusChargesCharger({dossierId: this.$route.params.dossierId}).then((d) => {
          this.metriques = d.data
        })
      },
      patrimoineImmoRafraichir() {
        api.patrimoineImmoCharger({dossierId: this.$route.params.dossierId}).then((d) => {
          this.metriques = d.data
        })
      },
      patrimoineFinancierRafraichir() {
        api.patrimoineFinancierCharger({dossierId: this.$route.params.dossierId}).then((d) => {
          this.metriques = d.data
        })
      },
      pretInfineCalculer(pretInfine) {
        const {dossierId} = this.$route.params
        api.pretInFineCalculer({dossierId, pretInfine}).then(() => {
          this.modalPretInfineVisible = false
          this.$router.push({name: 'avancesEtDeductions'})
        })
      },
      outilsCalculsAfficher() {
        return !(this.$dossier.professionnel && this.$dossier.projet_type_code === 'CPRO')
      },
      dossierRafraichir(cb = () => {
      }) {
        api.dossierCharger({dossierId: this.$route.params.dossierId})
          .then((r) => {
            // Si dossier nouvellement terminé
            if (!this.$dossier.saisie_complete
              && r.data.saisie_complete)
              this.$root.$emit('dossierComplet')

            this.$dossier.champsModifier(r.data)
            cb()
          })
      },
      navigate(){
        this.navOpened = false
      },
      appQuitter(){
        window.location.href = '/'
        this.btnReturn.click()
      },
      saisiePoursuivre(){
        this.dossierTerminer = false
        this.$root.$emit('dossierComplet')
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import "../assets/scss/colors";
    @import "../assets/scss/variables";

    .chargement {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255,255,255,0.8);
        position: absolute;
        z-index: 9999;

        div {
            position: absolute;
            color: $primary;
            left: 50%;
            top: 50%;
            font-size: 20px;
            margin-left: -120px;
        }
    }

    .cadre {
        @media screen and (max-width: 768px) {
            border: none;
        }
    }

    .cadre-content-principal {
        @media screen and (max-width: 768px) {
            padding: 0 !important;
        }
    }

    .cadre-conteneur, .dossier-conteneur {
        height: 100%;

        .cadre-tete {
            padding: 30px;
        }
    }

    .title {
        color: $black;
    }

    .cadre-content {
        height: 100%;
    }

    .dossier-content {
        display: flex;
        height: 100%;
        overflow: hidden;
    }

    .btn-next {
        margin-right: 0;
        margin-left: auto;
    }

    label[for='nav'] {
        cursor: pointer;
        position: absolute;
        z-index: 50;
        left: 10px;
        top: 10px;

        @media screen and (min-width: 769px) {
            display: none;
        }
    }

    #nav {
        display: none;
    }

    #nav:checked ~ .background {
        display: block;
    }

    #nav:checked ~ .cadre-content {

        .cadre-left {
            left: 0;
        }
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .5);
        z-index: 999;
        display: none;
        width: 100%;
        height: 100%;
    }

    .cadre-left {
        width: 25%;
        min-width: 250px;
        display: flex;
        flex-direction: column;
        height: 100%;

        @media screen and (max-width: 768px) {
            position: absolute;
            left: -100%;
            top: 0;
            width: calc(100% - 70px);
            max-width: 500px;
            z-index: 1000;
            background: white;
            transition: left .3s ease-in;
        }
    }

    .navigation {
        overflow-y: auto;
        flex-grow: 1;
        height: 100%;
    }

    .menu-contextuel {
        border: 1px solid #ddd;
        padding: 20px;
        border-radius: 3px;
        flex-shrink: 1;
        max-height: 230px;
        font-size: .9rem;

        .menu-en-tete {
            padding-bottom: 10px;
            border-bottom: 1px solid #ddd;
            margin-bottom: 15px;
            color: #333;
            font-size: 18px;
        }

        li {
            margin-bottom: 15px;
            list-style: none;
        }

        ul {
            margin: 0;
            padding: 0;
        }

        .cadre-content {
            overflow: hidden;
            padding: 0;
        }

        .metriques {
            display: flex;

            .values {
                text-align: right;
                color: $primary;
                font-weight: 500;
                margin: 0 0px 0 auto;
            }
        }

        &.menu-contextuel--revenu{
            min-height: 300px;
            max-height: none !important;
        }

        &.menu-contextuel--bien{
            min-height: 200px;
            max-height: none !important;
        }

        &.menu-contextuel--epargne{
            min-height: 200px;
            max-height: none !important;
        }
    }

    .settings {
        position: relative;
    }

    /*.router-content {
        @media screen and (max-width: 768px) {
            padding-top: 45px;
        }
    }*/

    .actions-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 10px;
    }

</style>
