import { Formulaire } from "../../Formulaire";

export class BilanPrecisions extends Formulaire {
    schema() {
        return {
            bilan_precisions: '',
            autofinancement: 0,

        }
    }

    validations(schema) {
        return {
            bilan_precisions: {},
            autofinancement: {}
        }
    }
}
