<template>
    <div class="modal-pret">
        <form
            @submit="valider"
            novalidate="true"
        >
            <div class="modal-content">
                <div class="cadre">
                    <div class="cadre-tete cadre-tete--menu">
                        Calcul du prêt infine
                    </div>
                    <div
                        id="formulaire"
                        class="cadre-content"
                        v-if="formulaire"
                    >
                        <div
                            class="form-group"
                            :class="{'form-group--error': $v.$dirty && $v.schema.montant.$error}"
                        >
                            <label>Montant du prêt in fine</label>
                            <input
                                class="form-control"
                                type="number"
                                placeholder="Montant"
                                v-model.number="$v.schema.montant.$model"
                            >
                            <div
                                class="form-error"
                                v-if="$v.$dirty && $v.schema.montant.$invalid"
                            >
                                Champ invalide
                            </div>
                        </div>

                        <div
                            class="form-group"
                            :class="{'form-group--error': $v.$dirty && $v.schema.assurance_vie_montant.$error}"
                        >
                            <label>Capital de départ sur l'assurance vie</label>
                            <input
                                class="form-control"
                                type="number"
                                placeholder="Montant"
                                v-model.number="$v.schema.assurance_vie_montant.$model"
                            >
                            <div
                                class="form-error"
                                v-if="$v.$dirty && $v.schema.assurance_vie_montant.$invalid"
                            >
                                Champ invalide
                            </div>
                        </div>

                        <div
                            class="form-group"
                            :class="{'form-group--error': $v.$dirty && $v.schema.assurance_vie_duree.$error}"
                        >
                            <label>Durée du contrat d'assurance vie (en mois)</label>
                            <input
                                class="form-control"
                                type="number"
                                placeholder="Durée"
                                v-model.number="$v.schema.assurance_vie_duree.$model"
                            >
                            <div
                                class="form-error"
                                v-if="$v.$dirty && $v.schema.assurance_vie_duree.$invalid"
                            >
                                Champ invalide
                            </div>
                        </div>

                        <div
                            class="form-group"
                            :class="{'form-group--error': $v.$dirty && $v.schema.assurance_vie_taux.$error}"
                        >
                            <label>Taux de l'assurance vie</label>
                            <input
                                class="form-control"
                                type="number"
                                placeholder="Taux"
                                v-model.number="$v.schema.assurance_vie_taux.$model"
                            >
                            <div
                                class="form-error"
                                v-if="$v.$dirty && $v.schema.assurance_vie_taux.$invalid"
                            >
                                Champ invalide
                            </div>
                        </div>
                        <button
                            class="btn btn-primary btn-large"
                            @click="assuranceCalculer"
                        >
                            Calculer les versements sur l'assurance vie
                        </button>
                        <div
                            class="form-group"
                            :class="{'form-group--error': $v.$dirty && $v.schema.assurance_vie_mensualite.$error}"
                        >
                            <label>Mensualité d'assurance vie</label>
                            <input
                                class="form-control"
                                type="number"
                                placeholder="Taux"
                                v-model.number="$v.schema.assurance_vie_mensualite.$model"
                            >
                            <!--<div
                                class="form-error"
                                v-if="$v.$dirty && $v.schema.assurance_vie_mensualite.$invalid"
                            >
                                Champ invalide
                            </div>-->
                        </div>

                    </div>
                    <div class="cadre-pied background-primary">
                        <button
                            class="btn btn-transparent"
                            @click="annuler"
                        >
                            Annuler
                        </button>
                        <button
                            type="submit"
                            class="btn btn-transparent btn-ptz"
                        >
                            Appliquer
                        </button>
                    </div>
                </div>
            </div>

        </form>

    </div>

</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'
    import Confirm from "../../../../components/Confirm.vue";

    export default {
        components: {Confirm},
        name: 'PretInFine',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema) : {}
            }
        },
        props: {
            calculer: Function,
            annuler: Function
        },
        data() {
            return {
                formulaire: null,
                schema: {}
            }
        },
        created() {
            this.init(this.$route.params.dossierId)
        },
        computed: {
            customValidations() {
                return this.formulaire.customValidations(this.schema)
            }
        },
        methods: {
            init(dossierId) {
                this.$formulaire.charger('commun', 'PretInFine', {dossierId})
                    .then((formulaire) => {
                        this.formulaire = formulaire
                        this.schema = formulaire.schema()

                        api.pretInFineCharger({dossierId}).then(d => {
                            this.schema = d.data
                        })
                    })
            },
            enregistrer() {
                api.pretInFineAssuranceCalculer({dossierId: this.$route.params.dossierId, pretInfine: this.schema}).then((d) => {
                    this.calculer(this.schema)
                })
            },
            assuranceCalculer(e){
                const { dossierId } = this.$route.params
                e.preventDefault()
                api.pretInFineAssuranceCalculer({ dossierId, pretInfine: this.schema }).then((d) => {
                    this.$v.schema.assurance_vie_mensualite.$model = d.data.assurance_vie_mensualite
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../../assets/scss/variables";

    .btn-large {
        margin-bottom: 10px;
    }
</style>
