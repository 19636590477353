<template>
    <div class="conteneur">
        <router-view
            :dossier-charge="dossierCharge"
        ></router-view>
        <transition name="modal-appear">
            <modal
                v-if="erreurModal"
                :close="() => erreurModal = false"
            >
                <div class="modal-content">
                    <div>
                        <h1>Accès non autorisé</h1>
                        <h3>Veuillez vous reconnecter à l'administration Courtisia</h3>
                    </div>
                </div>
            </modal>
        </transition>

    </div>
</template>

<script>

  import api from '@/api'
  import Modal from "../components/Modal.vue";

  export default {
    components: {Modal},
    name: 'Auth',
    props: {
      dossierCharge: Function
    },
    data() {
      return {
        erreurModal: false,
      }
    },
    created() {
      this.configurer()
    },
    updated() {
      this.configurer()
    },
    methods: {
      configurer() {
        const token = this.$route.params.token
        api.configurer(token, () => {
          this.erreurModal = true
        })
      }
    }
  }
</script>

<style lang="scss" scoped="scoped">
    @import "../assets/scss/colors";

    .conteneur {
        height: 100%;
    }

    .modal-content {
        background: $error;
        color: white;
        padding: 20px;
        text-align: center;
    }

</style>
