import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class ProjetDescription extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'projetTypes', cle: 'projetTypes'},
        {type: 'constantes', fonction: 'projetUsages', cle: 'projetUsages'},
        {type: 'constantes', fonction: 'projetPrecisions', cle: 'projetPrecisions'},
        {type: 'constantes', fonction: 'projetDestinations', cle: 'projetDestinations'},
        {type: 'constantes', fonction: 'projetAnciennete', cle: 'projetAnciennete'},
        {type: 'constantes', fonction: 'projetNormes', cle: 'projetNormes'},
        {type: 'constantes', fonction: 'projetPieces', cle: 'projetPieces'},
        {type: 'constantes', fonction: 'projetDpe', cle: 'projetDpe'},
        {type: 'constantes', fonction: 'devises', cle: 'devises'},
        {type: 'donnees', fonction: 'projetBienCharger', cle: 'projetBien'}
    ]

    schema() {
        return {
            projet_type_id: null,
            projet_usage_id: null,
            projet_precision_id: null,
            projet_destination_id: null,
            demarrage_mois: '',
            loyer_locatif: '',
            devise_id: null,
            projet_anciennete_id: null,
            projet_norme_id: null,
            projet_pieces_id: null,
            projet_dpe_id: null,
            projet_surface: '',
            projet_personnes: '',
            terrain_superficie: '',
        }
    }

    validations(schema, metadata) {
        const customValidations = this.customValidations(schema, metadata)
        return {
            projet_type_id: {required},
            projet_usage_id: {
                required: customValidations.projet_usage_id ? required : false
            },
            projet_precision_id: {
                required: customValidations.projet_precision_id ? required : false
            },
            projet_destination_id: {
                required: customValidations.projet_destination_id ? required : false
            },
            demarrage_mois: {
                required: customValidations.demarrage_mois ? required : false
            },
            loyer_locatif: {
                required: customValidations.loyer_locatif ? required : false
            },
            devise_id: {
                required: customValidations.devise_id ? required : false
            },
            dossier_pro: {},
            projet_anciennete_id: {
                required: customValidations.projet_anciennete_id ? required : false
            },
            projet_norme_id: {},
            projet_pieces_id: {
                required: customValidations.projet_pieces_id ? required : false
            },
            projet_dpe_id: {},
            projet_surface: {},
            projet_personnes: {
                required: customValidations.projet_personnes ? required : false
            },
            terrain_superficie: {}
        }
    }

    customValidations(schema, meta = {}) {
        const {projetTypes, dossier, projetAnciennetes, projetBien, compte} = meta
        const projetType = projetTypes && schema.projet_type_id ? projetTypes[schema.projet_type_id].code : null
        const projetAnciennete = projetAnciennetes && schema.projet_anciennete_id ? projetAnciennetes[schema.projet_anciennete_id].code : null

        return {
            projet_usage_id: !["RACH", "RENE", "SOUL", "AUTR", "TRAS", "SCPI"].includes(projetType),
            projet_destination_id: !["RACH", "RENE", "SOUL", "AUTR", "TRAS", "SCPI", "CPRO"].includes(projetType),
            projet_precision_id: !["RACH", "RENE", "SOUL", "AUTR", "TRAS", "SCPI", "CPRO"].includes(projetType),
            demarrage_mois: ['RACH', 'RENE', 'SOUL'].includes(projetType) || dossier.credits_nombre > 0,
            devise_id: dossier.multidevise && ["ACQU", "TRAV", "TERR", "CONS", "TECO", "VEFA", "SCPI"].includes(projetType),
            loyer_locatif: ["ACQU", "TRAV", "TERR", "CONS", "TECO", "VEFA", "SCPI"].includes(projetType),
            projet_personnes: !dossier.professionnel && !['RACH', 'RENE', 'SOUL', 'CPRO', 'AUTR', 'TRAS', 'SCPI'].includes(projetType),
            terrain_superficie: ['TECO', 'TERR'].includes(projetType),
            projet_norme_id: !dossier.professionnel && projetAnciennete === 'NEUF',
            dossier_pro: !dossier.professionnel && compte.case_dossier_pro_affiche,
            projet_anciennete_id: projetBien 
                                    && projetBien.bien_trouve 
                                    && !['SCPI', 'CPRO', 'RACH', 'RENE', 'SOUL'].includes(projetType),
            projet_pieces_id: !dossier.professionnel && (projetBien && projetBien.bien_trouve) && !['SCPI', 'CPRO', 'RACH', 'RENE', 'SOUL'].includes(projetType),
            projet_dpe_id: !dossier.professionnel || dossier.societe.statut_juridique_code == 'SCI',
            projet_surface: !dossier.professionnel || dossier.societe.statut_juridique_code == 'SCI'
        }
    }
}

export class ProjetBien extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'projetTypes', cle: 'projetTypes'},
        {type: 'donnees', fonction: 'projetCharger', cle: 'projet'}
    ]

    schema() {
        return {
            bien_trouve: null,
            compromis_signe: null,
            compromis_date: '',
            clause_suspensive_date: '',
            signature_acte_date: '',
            projet_adresse: '',
            projet_code_postal: '',
            projet_localite: ''
        }
    }

    validations(schema, {projet}) {
        const customValidations = this.customValidations(schema, {projet})
        return {
            bien_trouve: {},
            compromis_signe: {},
            compromis_date: {
                required: customValidations.compromis_date ? required : false,
            },
            clause_suspensive_date: {
                required: customValidations.clause_suspensive_date ? required : false,
            },
            signature_acte_date: {},
            projet_adresse: {},
            projet_code_postal: {},
            projet_localite: {}
        }
    }

    customValidations(schema, meta = {}) {
        const {projetTypes, projet} = meta
        const projetType = projetTypes && projet.projet_type_id ? projetTypes[projet.projet_type_id].code : null
        return {
            bien_trouve: !["CPRO", "RACH", "RENE", "SOUL"].includes(projetType),
            compromis_signe: !["CPRO", "RACH", "RENE", "SOUL"].includes(projetType),
            compromis_date: schema.compromis_signe && projetType !== "CPRO",
            clause_suspensive_date: schema.compromis_signe && projetType !== "CPRO",
            signature_acte_date: schema.compromis_signe && !["CPRO", "RACH", "RENE", "SOUL"].includes(projetType)
        }
    }
}

export class ProjetNotaire extends Formulaire {

    meta = [
        {type: 'donnees', fonction: 'notairesLister', cle: 'notaires'},
        {type: 'donnees', fonction: 'projetCharger', cle: 'projet'}
    ]

    schema() {
        return {
            notaire_active: false,
            notaire_id: null,
            notaire_contact_id: null
        }
    }

    validations(schema, contexte) {
        const notaire = contexte.notaires.find((a) => parseInt(a.id) === schema.notaire_id)
        const notaires = notaire ? notaire.contacts : []
        return {
            notaire_active: {},
            notaire_id: {
                required: schema.notaire_active ? required : false
            },
            notaire_contact_id: {
                required: schema.notaire_active && notaires.length ? required : false
            }
        }
    }
}

export class ProjetPrecisions extends Formulaire {
    schema() {
        return {
            projet_precisions: '',
        }
    }

    validations(schema) {
        return {
            projet_precisions: {},
        }
    }
}
