import { Formulaire } from '@/formulaires/Formulaire'
import { required, minLength } from 'vuelidate/lib/validators'

export class Professionnel extends Formulaire {

    meta = [
        { type: 'constantes', fonction: 'categoriesProfessionnelles', cle: 'categoriesProfessionnelles' },
        { type: 'constantes', fonction: 'contratTypes', cle: 'contratTypes' }
    ]

    schema () {
        return {
            id: '',
            categorie_professionnelle_ids: [],
            profession: '',
            contrat_type_ids: [],
            employeur: '',
            travail_lieu: '',
            travail_code_postal: '',
            embauche_date: '',
            periode_essai: false
        }
    }

    validations (schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            categorie_professionnelle_ids : {
                required,
                minLength: minLength(1)
            },
            profession: {
                required: customValidations.profession ? required : false,
                minLength: minLength(2)
            },
            contrat_type_ids : {
                required: customValidations.contrat_type_ids ? required : false,
            },
            employeur: {},
            travail_lieu: {},
            travail_code_postal: {},
            embauche_date: {},
            periode_essai: {}
        }
    }

    customValidations(schema, meta){
        const { dossier, compte, categoriesProfessionnelles } = meta
        let categoriesProCode = []
        if(schema.categorie_professionnelle_ids){
            categoriesProCode = schema.categorie_professionnelle_ids.map(c => {
                if(categoriesProfessionnelles[c]){
                    return categoriesProfessionnelles[c].code
                }
                return null
            })
        }
        const intersect = refTableau => testTableau => testTableau.reduce((acc, curr) => acc ? acc : refTableau.includes(curr), false)
        const intersectBase = intersect(['RETRA', 'SANS', 'DEMAN'])
        return {
            profession: !(categoriesProCode.length && intersectBase(categoriesProCode)),
            contrat_type_ids: !(categoriesProCode.length && intersectBase(categoriesProCode)),
            employeur: !(categoriesProCode.length && intersectBase(categoriesProCode)),
            travail_lieu: !(categoriesProCode.length && intersectBase(categoriesProCode)),
            travail_code_postal: !(categoriesProCode.length && intersectBase(categoriesProCode)),
            embauche_date: !(categoriesProCode.length && intersectBase(categoriesProCode)),
            periode_essai: !(categoriesProCode.length && intersect(['RETRA', 'SANS', 'DEMAN', 'COMME', 'PROLI', 'DIRIG', 'DENTI'])(categoriesProCode)),
        }
    }
}

export class ProfessionnelPrecisions extends Formulaire {
    schema() {
        return {
            professionnel_precisions: '',
            bilans_active: null
        }
    }

    validations(schema) {
        return {
            professionnel_precisions: {},
            bilans_active: {}
        }
    }
}
