<template>
    <div
        class="notification-container"
        :class="type"
    >
        {{content}}
    </div>
</template>

<script>
    export default {
        name: 'Notification',
        props: {
            content: String,
            type: String,
        },
        data () {
            return {
                visible: true,
            }
        },
        created () {
            setTimeout(() => {
                this.visible = false
            }, 2000)
        }
    }
</script>

<style lang="scss" scoped>
</style>
