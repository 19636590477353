import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class Revenu extends Formulaire {

    meta = [
        { type: 'constantes', fonction: 'devises', cle: 'devises' }
    ]

    schema () {
        return {
            id: '',
            devise_id: null,
            revenu_actuel: 0,
            revenu_mois: 12,
            revenu_annuel: 0,
            revenu_reference: 0,
            revenu_allocations: 0,
            revenu_apl: 0,
            revenu_pension: 0,
            revenu_autre: 0,
            revenu_total: 0
        }
    }

    validations (schema, { dossier }) {
        return {
            id: {},
            devise_id: {
              required: dossier.multidevise ? required : false
            },
            revenu_actuel: {required},
            revenu_mois: {required},
            revenu_annuel: {required},
            revenu_reference: {required},
            revenu_allocations: {required},
            revenu_apl: {required},
            revenu_pension: {required},
            revenu_autre: {required},
            revenu_total: {}
        }
    }
}

export class RevenuPrecisions extends Formulaire {
    schema() {
        return {
            revenu_precisions: '',
        }
    }

    validations(schema) {
        return {
            revenu_precisions: {},
        }
    }
}
