import { Formulaire } from '@/formulaires/Formulaire'
import { email, required } from 'vuelidate/lib/validators'

export class Configurations extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'prestationTypes', cle: 'prestationTypes'},
        {type: 'donnees', fonction: 'apporteursLister', cle: 'apporteurs'},
        {type: 'constantes', fonction: 'devises', cle: 'devises'},
    ]

    schema() {
        return {
            date_saisie: '',
            prestation_type_id: null,
            apporteur_affaire: false,
            apporteur_id: null,
            contact_id: null,
            devise_multiple: false,
            devise_1_id: null,
            devise_2_id: null,
            devise_taux_change: null

        }
    }

    validations(schema, contexte) {
        const apporteur = contexte.apporteurs.find((a) => parseInt(a.id) === schema.apporteur_id) || {contacts: []}
        return {
            date_saisie: {required},
            prestation_type_id: {required},
            apporteur_affaire: {},
            apporteur_id: {
                required: schema.apporteur_affaire ? required : false,
            },
            contact_id: {
                required: schema.apporteur_affaire && apporteur.contacts.length ? required : false,
            },
            devise_multiple: {},
            devise_1_id: {
                required: schema.devise_multiple ? required : false
            },
            devise_2_id: {
                required: schema.devise_multiple ? required : false
            },
            devise_taux_change: {
                required: schema.devise_multiple ? required : false
            }
        }
    }
}

export class Rdv extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'pieces', cle: 'pieces'},
        {type: 'donnees', fonction: 'apporteursLister', cle: 'apporteurs'},
    ]

    schema() {
        return {
            email: '',
            telephone_fixe: '',
            telephone_mobile: '',
            date: '',
            heure: '',
            lieu: '',
            interlocuteur_id: null,
            commentaires: '',
            interlocuteur_selectionne: false,
            piece_ids: [],
            retrocommission_active: false,
            retrocommission_apporteur_id: null,
            retrocommission_contact_id: null,
            retrocommission_contact: ''

        }
    }

    validations(schema, contexte) {
        const customValidations = this.customValidations(schema, contexte)
        return {
            email: {required, email},
            telephone_fixe: {},
            telephone_mobile: {},
            date: {required},
            heure: {required},
            lieu: {required},
            interlocuteur_id: {required},
            commentaires: {},
            interlocuteur_selectionne: {},
            piece_ids: {required},
            retrocommission_active: {},
            retrocommission_apporteur_id: {
                required: customValidations.retrocommission_apporteur_id ? required : false
            },
            retrocommission_contact_id: {
                required: customValidations.retrocommission_contact_id ? required : false
            },
            retrocommission_contact: {
                required: customValidations.retrocommission_contact ? required : false,
            }
        }
    }

    customValidations(schema, meta) {
        const {compte, apporteurs} = meta
        const apporteur = apporteurs.find((a) => parseInt(a.id) === schema.retrocommission_apporteur_id)
        const contacts = apporteur ? apporteur.contacts : []
        return {
            retrocommission_active: !compte.dossier_origine_requise,
            retrocommission_apporteur_id: compte.dossier_origine_requise || schema.retrocommission_active,
            retrocommission_contact_id: contacts.length && !(apporteur ? apporteur.saisie_manuelle : false),
            retrocommission_contact: apporteur ? apporteur.saisie_manuelle : false,
        }

    }
}
