<template>
    <form
        @submit="valider"
        novalidate="true"
    >
        <div class="cadre content-cadre background-white">
            <div class="cadre-tete cadre-tete--menu">
                Bien
                <formulaire-status
                    :form="$v"
                ></formulaire-status>
            </div>
            <div
                id="formulaire"
                class="cadre-content"
                v-if="formulaire"
            >

                <div
                    id="titulaire_code"
                    class="form-group"
                    v-if="customValidations.titulaire_code"
                    :class="{'form-group--error': $v.$dirty && $v.schema.titulaire_code.$invalid}"
                >
                    <label> Titulaire * </label>
                    <select
                        class="form-control"
                        v-model="$v.schema.titulaire_code.$model"
                    >
                        <option :value="null">Sélectionner</option>
                        <option
                            v-for="option in formulaire.donnees.titulaires"
                            :value="option.code"
                            v-html="option.libelle"
                        >
                        </option>
                    </select>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.titulaire_code.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>

                <div
                    id="projet_usage_id"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.projet_usage_id.$invalid}"
                >
                    <label> Destination * </label>
                    <select
                        class="form-control"
                        v-model.number="$v.schema.projet_usage_id.$model"
                    >
                        <option :value="null">Sélectionner</option>
                        <option
                            v-for="option in projetUsages"
                            :value="option.id"
                            v-html="option.lib"
                        >
                        </option>
                    </select>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.projet_usage_id.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>

                <div
                    id="devise_id"
                    v-if="customValidations.devise_id"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.devise_id.$invalid}"
                >
                    <label> Devise * </label>
                    <select
                        class="form-control"
                        v-model.number="$v.schema.devise_id.$model"
                    >
                        <option :value="null">Sélectionner</option>
                        <option
                            v-for="option in formulaire.constantes.devises"
                            :value="option.id"
                            v-html="option.lib"
                        >
                        </option>
                    </select>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.devise_id.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>

                <div
                    id="lieu"
                    class="form-group"
                >
                    <label> Lieu </label>
                    <input
                        class="form-control"
                        placeholder="Lieu"
                        v-model.trim="$v.schema.lieu.$model"
                    >
                </div>

                <div
                    id="description"
                    class="form-group"
                >
                    <label> Description </label>
                    <input
                        class="form-control"
                        placeholder="description"
                        v-model.trim="$v.schema.description.$model"
                    >
                </div>

                <div
                    id="achat_date"
                    class="form-group"
                >
                    <label> Date d'achat </label>
                    <input
                        class="form-control"
                        placeholder="Date d'achat"
                        v-model.trim="$v.schema.achat_date.$model"
                    >
                </div>

                <div
                    id="achat_prix"
                    class="form-group"
                >
                    <label> Prix d'achat </label>
                    <input
                        class="form-control"
                        placeholder="Montant"
                        v-model.number="$v.schema.achat_prix.$model"
                    >
                </div>

                <div
                    id="valeur"
                    class="form-group"
                >
                    <label> Valeur </label>
                    <input
                        class="form-control"
                        placeholder="Montant"
                        v-model.number="$v.schema.valeur.$model"
                    >
                </div>

                <div
                    id="garantie_type_id"
                    class="form-group"
                >
                    <label> Type de garantie </label>
                    <select
                        class="form-control"
                        v-model.number="$v.schema.garantie_type_id.$model"
                    >
                        <option :value="null">Sélectionner</option>
                        <option
                            v-for="option in formulaire.constantes.garantieTypes"
                            :value="option.id"
                            v-html="option.lib"
                        >
                        </option>
                    </select>
                </div>

                <div
                    id="loyer"
                    class="form-group"
                >
                    <label> Loyer </label>
                    <input
                        class="form-control"
                        placeholder="Montant"
                        v-model.number="$v.schema.loyer.$model"
                    >
                </div>

            </div>

            <div class="cadre-pied cadre-pied--crud background-primary">
                <button
                    type="button"
                    @click="formulaireAnnuler"
                    class="btn btn-transparent cancel-button"
                >
                    Annuler
                </button>
                <!--<button
                    class="btn btn-transparent"
                    @click="e => soumettre(e, 'enregistrerEtRediriger')"
                >
                    Enregistrer +
                    <div class="etape-suivante">passer à l'étape suivante</div>
                </button>-->
                <button
                    class="btn btn-transparent"
                    @click="e => soumettre(e, 'enregistrer')"
                >
                    Enregistrer
                </button>
            </div>

            <transition
                name="notification-afficher">

                <notification
                    v-if="notification.afficher"
                    :content="notification.texte"
                    :type="'success'"
                ></notification>
            </transition>

            <transition
                name="notification-afficher">
                <notification
                    v-if="enregistrement.afficher"
                    :content="enregistrement.texte"
                    :type="'warning'"
                ></notification>
            </transition>
        </div>
    </form>
</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'

    export default {
        name: 'Bien',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        beforeRouteUpdate(to, from, next){
            this.scrollTo(to)
            next()
        },
        data() {
            return {
                formulaire: null,
                schema: {},
                destination: ''
            }
        },
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema, this.metadataCreer()) : {}
            }
        },
        created() {
            const {dossierId, id} = this.$route.params
            this.$formulaire.charger('particulier', 'Bien', {dossierId: this.$dossier.id})
                .then((formulaire) => {
                    this.formulaire = formulaire
                    this.schema = formulaire.schema()

                    if (id)
                        api.bienCharger({dossierId, bienId: id})
                            .then((d) => {
                                this.schema = d.data
                                this.scrollTo(this.$route)
                            })
                })
        },
        computed: {
            customValidations(){
                return this.formulaire.customValidations(this.schema, this.metadataCreer())
            },
            projetUsages() {
                return this.formulaire.constantes.projetUsages.filter(({pro}) => pro === '0')
            },
        },
        methods: {
            soumettre(e, destination) {
                e.preventDefault()
                this.destination = destination
                this.valider(e)
            },
            enregistrer(next = null) {

                const methode = this.$route.params.id ? 'bienModifier' : 'bienCreer'
                return api[methode]({dossierId: this.$route.params.dossierId, bien: this.schema})
                    .then(() => {
                        this.notificationAfficher().then(() => {
                            if (!next) {
                                this.menuContinuer(this.destination)
                            }
                            if (methode === 'bienCreer') {
                                this.schema = this.formulaire.schema()
                            }
                        })
                    })
            },
            metadataCreer() {
                return {
                    dossier: this.$dossier
                }
            }
        }
    }
</script>
