<template>
    <div class="modal">
        <form
            @submit="valider"
            novalidate="true"
        >
            <div class="modal-content">
                <div class="cadre">
                    <div class="cadre-tete cadre-tete--menu">
                        Calcul de l'endettement locatif
                    </div>
                    <div
                        id="formulaire"
                        class="cadre-content"
                        v-if="formulaire"
                    >
                        <div>
                            <div
                                id="revenus_locatifs_ponderation"
                                class="form-group"
                                :class="{'form-group--error': $v.$dirty && $v.schema.revenus_locatifs_ponderation.$invalid}"
                            >
                                <label>Pondération sur les revenus locatifs (%) *</label>
                                <input
                                    class="form-control"
                                    placeholder="Montant"
                                    v-model.number="$v.schema.revenus_locatifs_ponderation.$model"
                                    autocomplete="disabled"
                                />
                                <div
                                    class="form-error"
                                    v-if="$v.$dirty && $v.schema.revenus_locatifs_ponderation.$invalid"
                                >
                                    Champ invalide
                                </div>
                            </div>


                            <div
                                id="endettement_locatif_id"
                                class="form-group"
                                :class="{'form-group--error': $v.$dirty && $v.schema.endettement_locatif_id.$invalid}"
                            >
                                <label> Calcul de l'endettement locatif * </label>
                                <select
                                    class="form-control"
                                    v-model.number="$v.schema.endettement_locatif_id.$model"
                                >
                                    <option :value="null">Sélectionner</option>
                                    <option v-for="option in formulaire.constantes.endettementTypes"
                                            :value="option.id"
                                            v-html="option.lib">
                                    </option>
                                </select>
                                <div
                                    class="form-error"
                                    v-if="$v.$dirty && $v.schema.endettement_locatif_id.$invalid"
                                >
                                    Champ invalide
                                </div>
                            </div>

                            <confirm
                                status="success"
                                confirmText="Appliquer"
                                v-if="confirmAfficher && endettementLocatif !== 'ko'"
                                annulerText="Modifier"
                                :annulerClick="annulerClick"
                                :confirmClick="appliquerClick"
                            >
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Nom du bien</th>
                                        <th>Revenu pondéré</th>
                                        <th>Mensualité(s) crédit(s)</th>
                                        <th v-if="!endettementClassique">Résultat mensuel</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr
                                        v-for="(bien, idx) in endettementLocatif.biens"
                                    >
                                        <td v-html="bien.bien"></td>
                                        <!--<td>
                                            <vue-numeric currency="€"
                                                         separator=" "
                                                         :value="bien.revenu"
                                                         :read-only="true"
                                            />
                                        </td>
                                        <td>
                                            <vue-numeric currency="€"
                                                         separator=" "
                                                         :value="bien.charge"
                                                         :read-only="true"
                                            />
                                        </td>
                                        <td v-if="!endettementClassique">
                                            <vue-numeric currency="€"
                                                         separator=" "
                                                         :value="bien.solde"
                                                         :read-only="true"
                                            />
                                        </td>-->
                                    </tr>
                                    </tbody>
                                </table>
                            </confirm>

                            <confirm
                                status="error"
                                confirmText="Recalculer"
                                v-if="confirmAfficher && endettementLocatif === 'ko'"
                                annulerText="Annuler"
                                :annulerClick="appliquerClick"
                                :confirmClick="annulerClick"
                            >
                                <p>Les critères d'endettement locatif ne sont pas remplis</p>
                            </confirm>
                        </div>

                    </div>
                    <div class="cadre-pied background-primary">
                        <button
                            class="btn btn-transparent"
                            @click="annuler"
                        >
                            Annuler
                        </button>
                        <button
                            type="submit"
                            class="btn btn-transparent btn-pas"
                        >
                            Calculer
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'
    import Confirm from "../../../../components/Confirm.vue";

    export default {
        components: {
            Confirm
        },
        name: 'EndettementLocatif',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema, this.metadataCreer()) : {}
            }
        },
        props: {
            calculer: Function,
            annuler: Function,
            data: Object
        },
        data() {
            return {
                formulaire: null,
                schema: {},
                endettementLocatif: null,
                confirmAfficher: false,
            }
        },
        created() {
            this.init()
        },
        computed: {
            customValidations() {
                return this.formulaire.customValidations(this.schema, this.metadataCreer())
            },
            endettementClassique() {
                const endettementType = this.formulaire.constantes.endettementTypes.find((type) => type.id === this.$v.schema.$model.endettement_locatif_id)
                return endettementType.code === 'CLASSIQUE'
            }
        },
        methods: {
            init() {
                this.$formulaire.charger('commun', 'EndettementLocatifModal')
                    .then((formulaire) => {
                        this.formulaire = formulaire
                        this.schema = this.data
                    })
            },
            enregistrer() {
                api.endettementLocatifCalculer({
                    dossierId: this.$route.params.dossierId,
                    endettementLocatif: this.schema
                }).then((d) => {
                    this.endettementLocatif = d.data
                    this.confirmAfficher = true
                })
            },
            metadataCreer() {
                return {
                    dossier: this.$dossier,
                }
            },
            annulerClick(e) {
                e.preventDefault()
                this.confirmAfficher = false
            },
            appliquerClick(e) {
                e.preventDefault()
                this.confirmAfficher = false
                this.calculer(e, this.$v.schema.$model)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../../assets/scss/variables";

    .modal {
        .btn-pas {
            margin-left: auto;
            margin-right: 0;
        }

        .modal-content {
            width: calc(100% - 60px) !important;
        }

        .cadre-content {
            display: flex;
            height: calc(100vh - #{$cadre-tete-height} - #{$cadre-pied-height} - 60px);

            > div {
                width: 50%;

                &:first-child {
                    margin-right: 40px;
                }
            }
        }
        .long-checkbox {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div {
                width: 80%;
            }
        }
    }
</style>
