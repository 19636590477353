<template>
    <form
        class="schema-form"
        @submit="valider"
        novalidate="true"
    >
        <div class="cadre content-cadre background-white">
            <div class="cadre-tete cadre-tete--menu">
                <span>
                    Emprunteur
                </span>
                <formulaire-status
                    :form="$v"
                ></formulaire-status>
            </div>
            <div
                id="formulaire"
                class="cadre-content"
                v-if="formulaire"
            >
                <div
                    id="civilite_id"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.civilite_id.$invalid}"
                >
                    <label>
                        Civilité *
                    </label>

                    <select
                        class="form-control"
                        v-model.number="$v.schema.civilite_id.$model"
                    >
                        <option :value="null">Sélectionner</option>
                        <option
                            v-for="option in formulaire.constantes.civilites"
                            :value="option.id"
                            v-html="option.lib"
                        >
                        </option>
                    </select>

                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.civilite_id.$invalid"
                    >
                        Champs invalide
                    </div>
                </div>

                <div
                    id="nom"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.nom.$invalid}">

                    <label> Nom * </label>

                    <input
                        type="text"
                        class="form-control"
                        placeholder="Nom"
                        v-model.trim="$v.schema.nom.$model"
                        autocomplete="disabled"
                    />

                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.nom.$invalid"
                    >
                        Champs invalide
                    </div>

                </div>

                <div
                    id="prenom"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.prenom.$invalid}"
                >

                    <label> Prénom * </label>

                    <input
                        type="text"
                        class="form-control"
                        placeholder="Prénom"
                        autocomplete="disabled"
                        v-model.trim="$v.schema.prenom.$model"
                    />
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.prenom.$invalid"
                    >
                        Champ invalide
                    </div>

                </div>

                <div
                    id="nom_jf"
                    class="form-group"
                >

                    <label>
                        Nom de jeune fille
                    </label>

                    <input
                        type="text"
                        class="form-control"
                        placeholder="Nom de jeune fille"
                        autocomplete="disabled"
                        v-model.trim="$v.schema.nom_jf.$model"
                    />
                </div>
            </div>

            <div class="cadre-pied cadre-pied--crud background-primary">
                <button
                    type="button"
                    @click="formulaireAnnuler"
                    class="btn btn-transparent cancel-button"
                >
                    Annuler
                </button>
                <button
                    class="btn btn-transparent btn-save-next"
                    @click="e => soumettre(e, 'enregistrerEtRediriger')"
                >
                    Enregistrer +
                    <div class="etape-suivante">passer à l'étape suivante</div>
                </button>
                <button
                    class="btn btn-transparent"
                    @click="e => soumettre(e, 'enregistrer')"
                >
                    Enregistrer
                </button>
            </div>

            <transition
                name="notification-afficher">

                <notification
                    v-if="notification.afficher"
                    :content="notification.texte"
                    :type="'success'"
                ></notification>
            </transition>

            <transition
                name="notification-afficher">
                <notification
                    v-if="enregistrement.afficher"
                    :content="enregistrement.texte"
                    :type="'warning'"
                ></notification>
            </transition>


        </div>

    </form>
</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'

    export default {
        name: 'Identite',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        beforeRouteUpdate(to, from, next) {
            this.scrollTo(to)
            next()
        },
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema) : {}
            }
        },
        data() {
            return {
                formulaire: null,
                schema: {},
                destination: ''
            }
        },
        created() {
            const {dossierId, id} = this.$route.params

            this.$formulaire.charger('particulier', 'Identite')
                .then((formulaire) => {
                    this.formulaire = formulaire
                    this.schema = formulaire.schema()

                    if (id)
                        return api.emprunteurCharger({dossierId: dossierId, emprunteurId: id})
                            .then(d => {
                                this.schema = d.data
                                this.scrollTo(this.$route)
                            })
                })
        },
        methods: {
            soumettre(e, destination) {
                e.preventDefault()
                this.destination = destination
                this.valider(e)
            },
            enregistrer(next = null) {

                const methode = this.$route.params.id ? 'emprunteurModifier' : 'emprunteurCreer'
                return api[methode]({dossierId: this.$route.params.dossierId, emprunteur: this.schema})
                    .then(() => {
                        this.notificationAfficher().then(() => {
                            if (!next) {
                                this.menuContinuer(this.destination)
                            }
                            if (methode === 'emprunteurCreer') {
                                this.schema = this.formulaire.schema()
                            }
                        })
                    })
            },
            supprimer($event, id) {

            }
        }
    }

</script>

<style lang="scss" scoped>

</style>
