<template>
    <form
        class="schema-form"
        @submit="valider"
        novalidate="true"
    >
        <div class="cadre content-cadre background-white">
            <div class="cadre-tete cadre-tete--menu">
                Société
                <formulaire-status
                    :form="$v"
                ></formulaire-status>
            </div>
            <div
                id="formulaire"
                class="cadre-content"
                v-if="formulaire"
            >
                <div
                    id="raison_sociale"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.raison_sociale.$invalid}"
                >

                    <label> Raison sociale * </label>

                    <input
                        type="text"
                        class="form-control"
                        placeholder="Raison sociale"
                        v-model.trim="$v.schema.raison_sociale.$model"
                        autocomplete="disabled"
                    />

                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.raison_sociale.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>

                <div
                    id="statut_juridique_id"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.statut_juridique_id.$invalid}"
                >

                    <label> Statut juridique * </label>

                    <select
                        class="form-control"
                        v-model.number="$v.schema.statut_juridique_id.$model">
                        <option :value="null">Sélectionner</option>
                        <option
                            v-for="option in formulaire.constantes.statutsJuridiques"
                            :value="option.id"
                            v-html="option.lib"
                        >
                        </option>
                    </select>

                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.statut_juridique_id.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>

            </div>

            <div class="cadre-pied background-primary">
                <button
                    class="btn btn-transparent"
                    @click="e => soumettre(e, 'enregistrerEtRediriger')"
                >
                    Enregistrer +
                    <div class="etape-suivante">passer à l'étape suivante</div>
                </button>
                <button
                    class="btn btn-transparent"
                    @click="e => soumettre(e, 'enregistrer')"
                >
                    Enregistrer
                </button>
            </div>

            <transition
                name="notification-afficher">

                <notification
                    v-if="notification.afficher"
                    :content="notification.texte"
                    :type="'success'"
                ></notification>
            </transition>

            <transition
                name="notification-afficher">
                <notification
                    v-if="enregistrement.afficher"
                    :content="enregistrement.texte"
                    :type="'warning'"
                ></notification>
            </transition>

        </div>

    </form>


</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'

    export default {
        name: 'Societe',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        beforeRouteUpdate(to, from, next){
            this.scrollTo(to)
            next()
        },
        data() {
            return {
                formulaire: null,
                schema: {},
                destination: ''
            }
        },
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema) : {}
            }
        },
        created() {
            const {dossierId, id} = this.$route.params
            this.$formulaire.charger('professionnel', 'Societe')
                .then((formulaire) => {
                    this.formulaire = formulaire
                    this.schema = formulaire.schema()

                    if (id) {
                        api.societeCharger({dossierId, societeId: id}).then((d) => {
                            this.schema = d.data
                            this.scrollTo(this.$route)
                        })
                    }
                })
        },
        methods: {
            soumettre(e, destination) {
                e.preventDefault()
                this.destination = destination
                this.valider(e)
            },
            enregistrer(next = null) {
                const {dossierId, id} = this.$route.params
                const methode = id ? 'societeModifier' : 'societeCreer'
                return api[methode]({dossierId, societe: this.$v.schema.$model})
                    .then(() => {
                        this.notificationAfficher().then(() => {
                            if (!next) {
                                this.menuContinuer(this.destination)
                            }
                        })
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

    .cadre-conteneur {
        height: 100%;

        .cadre {
            background: #fff;
        }
    }

    .nouveau-dossier {
        max-width: 500px;
        height: 100%;
        margin: auto;

        .cadre-pied {
            justify-content: space-between;
        }

        .create-btn {
            width: 100%;
        }
    }

</style>
