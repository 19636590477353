<template>
    <div class="etape-container">

        <div class="etape-menu"
             :class="{'menu-active': menuActive}"
        >
            <div
                class="cadre content-cadre background-gray">

                <div class="cadre-tete cadre-tete--menu">
                    <div>
                        <div>
                            <span v-if="!$dossier.professionnel">Emprunteurs</span>
                            <span v-if="$dossier.professionnel">Associé(s) et cautionnaire(s)</span>
                        </div>
                    </div>
                    <!--<router-link
                        v-if="!$dossier.saisie_complete && menu && menu.menu && menu.menu.length < 12"
                        :to="$utils.formulaireAller('identite', 'creer')"
                        tag="div"
                        class="btn btn-ajouter"
                    >
                        <i class="fas fa-plus-circle"></i>
                        Ajouter un associé
                    </router-link>-->
                </div>
                <div
                    id="menu"
                    class="cadre-content"
                >
                    <menu-warning
                        v-if="!$dossier.representant_legal"
                        :menu="menu.menu"
                    ></menu-warning>
                    <div
                        v-for="element in menu.menu"
                        :key="element.id"
                        class="menu-brique"
                    >
                        <router-link
                            v-if="element.id"
                            tag="div"
                            @click.native="$root.$emit('menuChanger')"
                            :to="$utils.formulaireAller('identite', element.id)"
                        >
                            <a class="menu-brique-tete">
                                <div>
                                    <i class="far fa-user-circle avatar-icon"></i>
                                    {{ element.label }}
                                </div>
                                <i
                                    class="fas fa-trash"
                                    v-if="estSupprimable(element.id)"
                                    @click="(event) => afficherSuppressionConfirmation(event, element)"
                                ></i>
                            </a>
                            <div
                                class="menu-brique-contenu"
                                v-if="element.champs && element.champs.length"
                            >
                                <menu-kpis
                                    :kpis="element.champs"
                                    :base-route="$utils.formulaireAller('identite', element.id)"
                                ></menu-kpis>
                            </div>
                            <div class="menu-brique-pied"
                                v-if="element.meta && element.meta.requis">
                                <completion
                                    :element="element"
                                ></completion>
                            </div>

                        </router-link>
                    </div>

                    <!--<div
                        class="menu-brique brique-creer"
                        v-if="!$dossier.saisie_complete"
                    >
                        <router-link
                            :to="this.$utils.formulaireAller('identite', 'creer')"
                        >
                            <div>
                                <i class="far fa-user-circle avatar-icon"></i>
                                <span v-if="$dossier.professionnel">Nouvel associé</span>
                                <span v-if="!$dossier.professionnel">Nouvel emprunteur</span>
                            </div>
                        </router-link>
                    </div>-->
                </div>
            </div>
        </div>

        <div class="etape-content">
            <transition
                name="fade"
                mode="out-in"
            >
                <router-view
                    :key="$route.path"
                    :menu-continuer="menuContinuer"
                ></router-view>
            </transition>
        </div>

        <transition
            name="fade"
            mode="out-in">
            <confirm
                v-if="elementASupprimer"
                status="ask-confirm"
                confirmText="Supprimer"
                :annulerClick="() => elementASupprimer = null"
                :confirmClick="() => supprimer()"
            >
                <div>
                    Souaitez-vous vraiment supprimer <span v-html="elementASupprimer.label"></span>?
                </div>
            </confirm>
        </transition>
    </div>
</template>

<script>
    import api from '@/api'
    import { menuMixin } from '@/utils/formulaire'

    export default {
        name: 'IdentiteMenu',
        mixins: [
            menuMixin
        ],
        props: {
            navigation: Array
        },
        data() {
            return {
                elementASupprimer: null
            }
        },
        methods: {
            charger() {
                const {dossierId} = this.$route.params
                return api.emprunteursMenu({dossierId, etape: 'identites'})
            },
            initialiser() {
                /* on redirige vers le formulaire edition si présent dans l'url */
                /*if (this.$route.params.id) {
                    this.$router.push(this.$utils.formulaireAller('identite', this.$route.params.id))
                    return
                }

                this.redirect(this.menu)*/
            },
            supprimer() {
                api.emprunteurSupprimer({
                    dossierId: this.$route.params.dossierId,
                    emprunteurId: this.elementASupprimer.id
                })
                    .then(() => {
                        this.$root.$emit('dossierRafraichir', () => {
                            this.menuCharger()
                            this.elementASupprimer = null
                        })
                    })
            },
            afficherSuppressionConfirmation($event, elem) {
                $event.preventDefault()
                this.elementASupprimer = elem
            },
            estSupprimable(emprunteurId) {
                if (emprunteurId === 'emprunteur' || this.$dossier.saisie_complete) {
                    return false
                }
                if (emprunteurId === 'coemprunteur') {
                    return this.menu.menu.length === 2
                }
                return true
            },
            redirect(menu) {
                if (menu.menu.length === 12 || this.$dossier.saisie_complete) {
                    const dernierEmprunteur = menu.menu[menu.menu.length - 1]
                    this.$router.push(this.$utils.formulaireAller('identite', dernierEmprunteur.id))
                    return
                }
                this.$router.push(this.$utils.formulaireAller('identite', 'creer'))
            },
            menuContinuer(destination) {
                this.menuCharger().then(() => {
                    if (destination === 'enregistrerEtRediriger') {
                        this.$router.push(this.$utils.prochaineEtapeNavigationAller('identite', this.navigation))
                        return
                    }
                    if(!this.$dossier.saisie_complete){
                        this.$router.push(this.$utils.prochaineEtapeAller('identite', this.$route, this.navigation))
                    }
                })
            }
        }
    }

</script>

<style lang="scss" scoped>
    .edit-icon {
        margin-right: 0;
        margin-left: auto;
    }
</style>
