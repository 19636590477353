import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class Credit extends Formulaire {

    meta = [
        { type: 'constantes', fonction: 'pretTypes', cle: 'pretTypes' },
        { type: 'constantes', fonction: 'endettementTypes', cle: 'endettementTypes' },
        { type: 'constantes', fonction: 'devises', cle: 'devises' },
        { type: 'donnees', fonction: 'titulairesLister', cle: 'titulaires' },
        { type: 'donnees', fonction: 'biensMenu', cle: 'biens' }
    ]

    schema () {
        return {
            rachat: false,
            titulaire_code: '',
            libelle: '',
            pret_type_code: null,
            preteur: '',
            devise_id: null,
            bien_id: null,
            duree: 0,
            date_debut: null,
            date_fin: null,
            restant_mois: 0,
            valeur: 0,
            capital_restant: 0,
            taux: 0,
            mensualite_actuelle: 0,
            assurance: 0,
            date_palier2: null,
            mensualite_palier2: 0,
            date_palier3: null,
            mensualite_palier3: 0,
            date_palier4: null,
            mensualite_palier4: 0,
            date_palier5: null,
            mensualite_palier5: 0,
            endettement_exclu: false,
        }
    }

    validations (schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            rachat: {},
            titulaire_code: {
                required: customValidations.titulaire_code ? required : false,
            },
            libelle: {},
            pret_type_code: {},
            preteur: {},
            devise_id: {
              required: customValidations.devise_id ? required : false,
            },
            bien_id: {},
            duree: {},
            date_debut: {},
            date_fin: {},
            restant_mois: {},
            valeur: {},
            capital_restant: {},
            taux: {},
            mensualite_actuelle: {},
            assurance: {},
            date_palier2: {},
            mensualite_palier2: {},
            date_palier3: {},
            mensualite_palier3: {},
            date_palier4: {},
            mensualite_palier4: {},
            date_palier5: {},
            mensualite_palier5: {},
            endettement_exclu: {},
        }
    }

    customValidations(schema, meta){
        const { dossier } = meta
        return {
            titulaire_code: dossier.professionnel || dossier.emprunteurs_total > 2,
            devise_id: dossier.multidevise
        }
    }

}

export class EndettementLocatif extends Formulaire {

    meta = [
        { type: 'constantes', fonction: 'endettementLocatifTypes', cle: 'endettementTypes' },
    ]

    schema() {
        return {
            revenus_locatifs_ponderation: 0,
            endettement_locatif_id: null
        }
    }

    validations(schema) {
        return {
            revenus_locatifs_ponderation: {
                required
            },
            endettement_locatif_id: {
                required
            }
        }
    }
}

export class Divers extends Formulaire {

    meta = [
        { type: 'constantes', fonction: 'endettementTypes', cle: 'endettementTypes' },
        { type: 'constantes', fonction: 'devises', cle: 'devises' }
    ]

    schema () {
        return {
            devise_id: null,
            endettement_impot_id: null,
            impot_mensualite: 0,
            loyer_maintenu: false,
            loyer_mensualite: 0,
            pension: true,
            pension_mensualite: 0,
            autre_mensualite: 0
        }
    }

    validations (schema, { dossier }) {
        return {
            devise_id: {
                required: dossier && dossier.multidevise ? required : false
            },
            endettement_impot_id: {
                required
            },
            impot_mensualite: {},
            loyer_maintenu: {},
            loyer_mensualite: {},
            pension: {},
            pension_mensualite: {},
            autre_mensualite: {}
        }
    }
}

export class ChargePrecisions extends Formulaire {
    schema() {
        return {
            charge_precisions: '',
        }
    }

    validations(schema) {
        return {
            charge_precisions: {},
        }
    }
}
