<template>
    <vue-multiselect
        :placeholder="placeholder"
        selectLabel=""
        :multiple="true"
        :closeOnSelect="false"
        :value="value"
        :options="options"
        :limit="0"
        open-direction="bottom"
        :limit-text="limitText"
        :searchable="false"
        :class="{'no-multiline': value && value.length > 0}"
        :disabled="disabled"
        @select="(option) => select(option)"
    >
        <template slot="option" slot-scope="props">
            <div class="option__desc">
                <i
                    class="fas fa-check-circle"
                    :class="{'active' : selectionne(props.option.id)}"
                ></i>
                <span class="option__title" v-html="props.option.lib"></span>
            </div>
        </template>
    </vue-multiselect>
</template>

<script>
    import VueMultiselect from 'vue-multiselect'

    export default {
        components: {VueMultiselect},
        name: 'Multiselect',
        props: {
            value: Array,
            options: Array,
            placeholderText: String,
            disabled: Boolean,
            limitText: Function,
            selectAction: Function
        },
        computed: {
            placeholder() {
                if (!this.value || this.value.length === 0) {
                    return this.placeholderText
                }
                return null
            }
        },
        methods: {
            selectionne(option_id) {
                return this.value && this.value.indexOf(parseInt(option_id)) > -1
            },
            select(option) {
                let value = null
                const id = parseInt(option.id)
                if (this.selectionne(id)) {
                    value = this.value && this.value.filter(_id => _id !== id)
                } else {
                    value = this.value && this.value.concat(id) || [id]
                }
                this.selectAction(value && value.length ? value : null)
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
