import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class Identite extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'civilites', cle: 'civilites'},
        {type: 'constantes', fonction: 'cautions', cle: 'cautions'},
        {type: 'donnees', fonction: 'personneMoraleCharger', cle: 'personneMorale'}
    ]

    schema() {
        return {
            id: '',
            personne_morale: null,
            raison_sociale: '',
            nom: '',
            prenom: '',
            civilite_id: null,
            nom_jf: '',
            gerant: false,
            capital_part: 0,
            caution_code: null
        }
    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            id: {},
            personne_morale: {},
            raison_sociale: {
                required: schema.personne_morale ? required : false
            },
            nom: {
                required: !schema.personne_morale ? required : false
            },
            prenom: {
                required: !schema.personne_morale ? required : false
            },
            civilite_id: {
                required: !schema.personne_morale ? required : false
            },
            nom_jf: {},
            gerant: {},
            capital_part: {
                required: customValidations.capital_part ? required : false,
            },
            caution_code: {}
        }
    }

    customValidations(schema, meta) {
        const {dossier, compte, personneMorale} = meta
        return {
            capital_part: parseFloat(personneMorale.capital) > 0,
        }
    }


}
