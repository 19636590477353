<template>
    <div class="etape-container">

        <div class="etape-menu">
            <div
                class="cadre content-cadre background-gray">

                <div class="cadre-tete cadre-tete--menu">

                    <span>Société</span>
                </div>
                <div
                    id="menu"
                    class="cadre-content"
                >
                    <div
                        class="menu-brique"
                        v-for="element in menu.menu"
                    >

                        <router-link
                            tag="div"
                            :to="$utils.formulaireAller('societe', element.id)">
                            <a class="menu-brique-tete">
                                <div>
                                    {{element.label}}
                                </div>
                            </a>
                            <div
                                class="menu-brique-contenu"
                                v-if="element.champs && element.champs.length"
                            >
                                <menu-kpis
                                    :kpis="element.champs"
                                    :base-route="$utils.formulaireAller('societe', element.id)"
                                ></menu-kpis>
                            </div>
                            <div class="menu-brique-pied"
                                v-if="element.meta && element.meta.requis">
                                <completion
                                    :element="element"
                                ></completion>
                            </div>
                        </router-link>


                    </div>
                </div>
            </div>
        </div>

        <div class="etape-content">
            <transition
                name="fade"
                mode="out-in"
            >
                <router-view
                    :key="$route.path"
                    :menu-continuer="menuContinuer"
                ></router-view>
            </transition>
        </div>

    </div>
</template>

<script>
    import api from '@/api'
    import { menuMixin } from '@/utils/formulaire'

    export default {
        name: 'SocieteMenu',
        mixins: [
            menuMixin
        ],
        props: {
            navigation: Array
        },
        methods: {
            charger() {
                const {dossierId} = this.$route.params
                return api.societeMenu({dossierId})
            },
            initialiser() {
                const menu = this.menu.menu.find((m) => m.meta && m.meta.complet < m.meta.requis)
                if (menu) {
                    this.$router.push(this.$utils.formulaireAller('societe', menu.id))
                } else {
                    this.$router.push(this.$utils.formulaireAller('societe', 'personne_morale'))
                }
            },
            menuContinuer() {
                this.menuCharger().then(() => {
                    this.$router.push(this.$utils.prochaineEtapeAller('societe', this.$route, this.navigation))
                })
            }
        }
    }

</script>

<style lang="scss" scoped>
    .edit-icon {
        margin-right: 0;
        margin-left: auto;
    }
</style>
