import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class FinancementPreferences extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'tauxTypes', cle: 'tauxTypes'}
    ]

    schema() {
        return {
            preference_duree: '',
            preference_taux: null,
            preference_mensualite: '',
            preference_quotepart: 0,
            taux_type_id: null,
            duree_prefinancement: 0
        }
    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            preference_duree: {
                required
            },
            preference_taux: {},
            preference_mensualite: {},
            preference_quotepart: {},
            taux_type_id: {
                required
            },
            prefinancement_duree: {
                required: customValidations.prefinancement_duree ? required : false
            },
            ltv: {},
            dscr: {}
        }
    }

    customValidations(schema, meta) {
        const {dossier, compte} = meta
        return {
            prefinancement_duree: dossier.projet_type_code === 'VEFA',
            preference_mensualite: compte.mensualite_souhaitee_affiche,
            ltv: compte.calcul_du_ratio_affiche,
            dscr: compte.calcul_du_ratio_affiche
        }
    }
}

export class FinancementRecherches extends Formulaire {

    meta = []

    schema() {
        return {
            organisme_consulte: false,
            banque_change: false,
            preteurs_exclus: ''
        }
    }

    validations(schema) {
        return {
            organisme_consulte: {},
            banque_change: {},
            preteurs_exclus: {}
        }
    }
}

export class FinancementPrecisions extends Formulaire {
    schema() {
        return {
            financement_precisions: '',
        }
    }

    validations(schema) {
        return {
            financement_precisions: {},
        }
    }
}
