import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'


export class Honoraires extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'projetTypes', cle: 'projetTypes'},
        {type: 'donnees', fonction: 'projetCharger', cle: 'projet'},
    ]

    schema() {
        return {
            honoraires_banque_pourcentage: 0,
            honoraires_client_pourcentage: 0,
            honoraires_client_montant: 0,
            honoraires_ptz_inclus: false,
            honoraires_emprunt_inclus: null,
            honoraire_gain_calcul: false,
        }

    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            honoraires_banque_pourcentage: {
                required: customValidations.honoraires_banque_pourcentage ? required : false
            },
            honoraires_client_pourcentage: {
                required: customValidations.honoraires_client_pourcentage ? required : false
            },
            honoraires_ptz_inclus: {},
            honoraires_emprunt_inclus: {
                required: customValidations.honoraires_emprunt_inclus ? required : false
            },
            honoraire_gain_calcul: {},
            honoraires_client_montant: {
                required: customValidations.honoraires_client_montant ? required : false,
            }
        }
    }

    customValidations(schema, meta) {
        const {dossier, compte} = meta
        return {
            honoraires_banque_pourcentage: compte.honoraires_banque_active && dossier.prestation_type_code === 'CLASSIQUE',
            honoraires_ptz_inclus: compte.honoraires_banque_active && dossier.prestation_type_code === 'CLASSIQUE' && dossier.ptz_montant > 0,
            honoraire_gain_calcul: dossier.prestation_type_code === 'CONSEIL' && ['RACH', 'RENE'].includes(dossier.projet_type_code) && compte.honoraires_client_active,
            honoraires_client_pourcentage: compte.honoraires_client_active || dossier.prestation_type_code === 'CONSEIL',
            honoraires_emprunt_inclus: (compte.honoraires_client_active || dossier.prestation_type_code === 'CONSEIL') && !schema.honoraire_gain_calcul,
            honoraires_client_montant: (compte.honoraires_client_active || dossier.prestation_type_code === 'CONSEIL') && !schema.honoraire_gain_calcul,

        }
    }
}

export class Retrocommissions extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'retrocommissionTypes', cle: 'retrocommissions'},
        {type: 'constantes', fonction: 'retrocommissionMandantTypes', cle: 'retrocommissionMandantTypes'},
    ]

    schema() {
        return {
            retrocommission_pourcentage: 0,
            retrocommission_montant: 0,
            retrocommission_type_code: null,
            retrocommission_ptz_inclus: false,
        }

    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            retrocommission_pourcentage: {
                required: customValidations.retrocommission_pourcentage ? required : false
            },
            retrocommission_montant: {
                required: customValidations.retrocommission_montant ? required : false
            },
            retrocommission_type_code: {
                required: customValidations.retrocommission_type_code ? required : false
            },
            retrocommission_ptz_inclus: {}
        }
    }

    customValidations(schema, meta) {
        const {dossier, compte} = meta
        return {
            retrocommission_pourcentage: dossier.retrocommission_active
            && schema.retrocommission_type_code !== 'FIXE',
            retrocommission_montant: dossier.retrocommission_active,
            retrocommission_type_code: (dossier.retrocommission_active
                && compte.honoraires_banque_active
                && compte.honoraires_client_active
                && dossier.prestation_type_code === 'CLASSIQUE')
            || (!dossier.possesseur.parametres.commission_mandant_banque && !dossier.possesseur.parametres.commission_mandant_client && !dossier.possesseur.parametres.commission_mandant_client_euros),
            retrocommission_ptz_inclus: compte.honoraires_banque_active && dossier.prestation_type_code === 'CLASSIQUE' && dossier.ptz_montant > 0,

        }
    }
}

export class CommissionsMandant extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'retrocommissionMandantTypes', cle: 'retrocommissionMandantTypes'},
    ]

    schema() {
        return {
            commission_mandant: 0,
            //commission_mandant_apporteur: 0,
            commission_mandant_banque: 0,
            commission_mandant_client: 0,
            commission_mandant_client_euros: 0,
            commission_mandant_type_code: null,
            retrocommission_deduite: false,
        }

    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            commission_mandant: {
                required: customValidations.commission_mandant ? required : false,
            },
            /*commission_mandant_apporteur: {
                required: customValidations.commission_mandant_apporteur ? required : false,
            },*/
            commission_mandant_banque: {
                required: customValidations.commission_mandant_banque ? required : false,
            },
            commission_mandant_client: {
                required: customValidations.commission_mandant_client ? required : false,
            },
            commission_mandant_client_euros: {
                required: customValidations.commission_mandant_client_euros ? required : false,
            },
            commission_mandant_type_code: {
                required: customValidations.commission_mandant_type_code ? required : false,
            },
            retrocommission_deduite: {},
        }
    }

    customValidations(schema, meta) {
        const {dossier, compte} = meta
        return {
            commission_mandant: schema.commission_mandant_type_code === 'GLOBAL',
            //commission_mandant_apporteur: true,
            commission_mandant_banque: schema.commission_mandant_type_code === 'SEPARE',
            commission_mandant_client: schema.commission_mandant_type_code === 'SEPARE',
            commission_mandant_client_euros: schema.commission_mandant_type_code === 'SEPARE',
            commission_mandant_type_code: true,
            retrocommission_deduite: dossier.retrocommission_active
        }
    }
}

export class CommissionsLicencie extends Formulaire {

    meta = []

    schema() {
        return {
            commission_licencie: 0,
        }

    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            commission_licencie: {
                required: true
            }
        }
    }

    customValidations(schema, meta) {
        const {dossier, compte} = meta
        return {
            commission_licencie: true,
        }
    }
}

export class CommissionsApporteur extends Formulaire {

    meta = []

    schema() {
        return {
            commission_apporteur: 0,
        }

    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            commission_apporteur: {
                required: true
            }
        }
    }

    customValidations(schema, meta) {
        const {dossier, compte} = meta
        return {
            commission_apporteur: true,
        }
    }
}

export class CommissionsFranchiseur extends Formulaire {

    meta = []

    schema() {
        return {
            commission_franchiseur: 0,
        }

    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            commission_franchiseur: {
                required: true
            }
        }
    }

    customValidations(schema, meta) {
        const {dossier, compte} = meta
        return {
            commission_franchiseur: true,
        }
    }
}
