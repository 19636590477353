<template>
    <div class="modal-ptz">
        <form
            @submit="valider"
            novalidate="true"
        >
            <div class="modal-content">
                <div class="cadre">
                    <div class="cadre-tete cadre-tete--menu">
                        Calcul du prêt à taux zéro
                    </div>
                    <div
                        id="formulaire"
                        class="cadre-content"
                        v-if="formulaire"
                    >
                        <div>
                            <div
                                id="proprietaire_nouveau"
                                class="form-group long-checkbox"
                            >
                                <div>
                                    <label> Nouveau Propriétaire </label>
                                    <small>
                                        L'emprunteur certifie ne pas avoir été propriétaire de sa résidence principale au cours des 2 années précédant la demande de prêt
                                    </small>
                                </div>

                                <toggle-button
                                    :labels="{checked: 'Oui', unchecked: 'Non'}"
                                    :sync="true"
                                    :width="65"
                                    :height="30"
                                    v-model="$v.schema.proprietaire_nouveau.$model"
                                />
                            </div>
                            <div
                                id="invalidite"
                                class="form-group long-checkbox"
                            >
                                <div>
                                    <label> Personne invalide </label>
                                    <small>
                                        L'emprunteur est titulaire d'une carte d'invalidité et dans l'incapacité d'exercer une activité professionnelle
                                    </small>
                                </div>

                                <toggle-button
                                    :labels="{checked: 'Oui', unchecked: 'Non'}"
                                    :sync="true"
                                    :width="65"
                                    :height="30"
                                    v-model="$v.schema.invalidite.$model"
                                />
                            </div>
                            <div
                                id="allocation_beneficiaire"
                                class="form-group long-checkbox"
                            >
                                <div>
                                    <label> Bénéficiaire de l'Allocation d'Éducation de l'Enfant Handicapé </label>
                                    <small>
                                        L'emprunteur est bénéficiaire de l'allocation adulte handicapé (AAH) ou l'allocation d'éducation de l'enfant handicapé (AEEH)
                                    </small>
                                </div>

                                <toggle-button
                                    :labels="{checked: 'Oui', unchecked: 'Non'}"
                                    :sync="true"
                                    :width="65"
                                    :height="30"
                                    v-model="$v.schema.allocation_beneficiaire.$model"
                                />
                            </div>
                            <div
                                id="sinistre"
                                class="form-group long-checkbox"
                            >
                                <div>
                                    <label> Aide aux victimes </label>
                                    <small>
                                        L'emprunteur est victime d'une catastrophe (par exemple: catastrophe naturelle, technologique) ayant rendu sa résidence principale définitivement inhabitable
                                    </small>
                                </div>

                                <toggle-button
                                    :labels="{checked: 'Oui', unchecked: 'Non'}"
                                    :sync="true"
                                    :width="65"
                                    :height="30"
                                    v-model="$v.schema.sinistre.$model"
                                />
                            </div>
                            <div
                                id="existant_hlm"
                                v-if="customValidations.existant_hlm"
                                class="form-group long-checkbox"
                            >
                                <div>
                                    <label> Logement HLM </label>
                                    <small>
                                        Logement existant acquis auprès d'un organisme HLM
                                    </small>
                                </div>

                                <toggle-button
                                    :labels="{checked: 'Oui', unchecked: 'Non'}"
                                    :sync="true"
                                    :width="65"
                                    :height="30"
                                    v-model="$v.schema.existant_hlm.$model"
                                />
                            </div>
                            <div
                                id="existant_travaux"
                                v-if="customValidations.existant_travaux"
                                class="form-group long-checkbox"
                            >
                                <div>
                                    <label> Logement rénové </label>
                                    <small>
                                        Logement existant dans lequel sont effectués des travaux à hauteur d'au moins <b>25% du coût total de l'opération</b>
                                    </small>
                                </div>

                                <toggle-button
                                    :labels="{checked: 'Oui', unchecked: 'Non'}"
                                    :sync="true"
                                    :width="65"
                                    :height="30"
                                    v-model="$v.schema.existant_travaux.$model"
                                />
                            </div>
                        </div>
                        <div>
                            <div
                                class="form-group"
                                :class="{'form-group--error': $v.$dirty && $v.schema.projet_anciennete_id.$error}"
                            >
                                <label> Neuf / Ancien <span
                                    v-if="customValidations.projet_anciennete_id">*</span></label>
                                <select
                                    class="form-control"
                                    v-model.number="$v.schema.projet_anciennete_id.$model"
                                >
                                    <option :value="null">Sélectionner</option>
                                    <option v-for="option in formulaire.constantes.projetAnciennetes"
                                            v-bind:value="option.id"
                                            v-html="option.lib">
                                    </option>
                                </select>

                                <div
                                    class="form-error"
                                    v-if="$v.$dirty && $v.schema.projet_anciennete_id.$invalid"
                                >
                                    Champ invalide
                                </div>
                            </div>
                            <div
                                class="form-group"
                                :class="{'form-group--error': $v.$dirty && $v.schema.projet_personnes.$error}"
                                v-if="customValidations.projet_personnes"
                            >
                                <label>Nombre de personnes destinées à occuper le logement *</label>
                                <input
                                    class="form-control"
                                    type="number"
                                    placeholder="Nombre"
                                    v-model.trim="$v.schema.projet_personnes.$model"
                                    autocomplete="disabled"
                                >
                                <div
                                    class="form-error"
                                    v-if="$v.$dirty && $v.schema.projet_personnes.$invalid"
                                >
                                    Champ invalide
                                </div>
                            </div>
                            <div
                                class="form-group"
                            >
                                <label>Code postal</label>
                                <autocomplete-adresse
                                    placeholderText="Code postal"
                                    :value="$v.schema.projet_code_postal.$model"
                                    searchKey="postal_code"
                                    :selectAction="selectAction"
                                    :inputAction="(v) => $v.schema.projet_code_postal.$model = v"
                                ></autocomplete-adresse>
                            </div>
                            <div
                                class="form-group"
                                :class="{'form-group--error': $v.$dirty && $v.schema.projet_localite.$error}"
                            >
                                <label>Commune</label>
                                <autocomplete-adresse
                                    placeholderText="Ville"
                                    :value="$v.schema.projet_localite.$model"
                                    searchKey="ville"
                                    :selectAction="selectAction"
                                    :inputAction="(v) => $v.schema.projet_localite.$model = v"
                                ></autocomplete-adresse>
                            </div>
                            <div
                                class="form-group"
                                :class="{'form-group--error': $v.$dirty && $v.schema.revenu_reference.$error}"
                            >
                                <label>Revenu fiscal de référence <b>N-2</b> *</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Montant"
                                    v-model.trim="$v.schema.revenu_reference.$model"
                                    autocomplete="disabled"
                                >
                                <div
                                    class="form-error"
                                    v-if="$v.$dirty && $v.schema.revenu_reference.$invalid"
                                >
                                    Champ invalide
                                </div>
                            </div>
                            <div
                                class="form-group"
                                :class="{'form-group--error': $v.$dirty && $v.schema.revenu_reference_conjoint.$error}"
                                v-if="customValidations.revenu_reference_conjoint"
                            >
                                <label>Revenu fiscal de référence <b>N-2</b> co-emprunteur *</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Montant"
                                    v-model.trim="$v.schema.revenu_reference_conjoint.$model"
                                    autocomplete="disabled"
                                >
                                <div
                                    class="form-error"
                                    v-if="$v.$dirty && $v.schema.revenu_reference_conjoint.$invalid"
                                >
                                    Champ invalide
                                </div>
                            </div>

                            <confirm
                                status="success"
                                confirmText="Appliquer"
                                v-if="confirmAfficher && ptzDonnees.succes"
                                :confirmClick="appliquerClick"
                            >
                                <div>
                                    Vous êtes éligible au Prêt à taux zéro avec les modalités suivantes:
                                    <ul>
                                        <li>
                                            Zone: {{ptzDonnees.zone}}
                                        </li>
                                        <li>Différé: {{ptzDonnees.differe}} mois</li>
                                        <li>Durée: {{ptzDonnees.duree}} ans</li>
                                        <li>Montant:
                                            <vue-numeric
                                                :currency="$compte.societe_devise"
                                                emptyValue="0"
                                                :precision="2"
                                                :readOnly="true"
                                                thousandSeparator=" "
                                                :value="ptzDonnees.montant || 0"
                                                currencySymbolPosition="suffix"
                                            />
                                        </li>
                                        <li>Mensualité période 1:
                                            <vue-numeric
                                                :currency="$compte.societe_devise"
                                                emptyValue="0"
                                                :precision="2"
                                                :readOnly="true"
                                                thousandSeparator=" "
                                                :value="ptzDonnees.mensualite_1 || 0"
                                                currencySymbolPosition="suffix"
                                            />
                                        </li>
                                        <li>Mensualité période 2:
                                            <vue-numeric
                                                :currency="$compte.societe_devise"
                                                emptyValue="0"
                                                :precision="2"
                                                :readOnly="true"
                                                thousandSeparator=" "
                                                :value="ptzDonnees.mensualite_2 || 0"
                                                currencySymbolPosition="suffix"
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </confirm>
                            <confirm
                                status="error"
                                confirmText="Recalculer"
                                v-if="confirmAfficher && !ptzDonnees.succes"
                                :confirmClick="annulerClick"
                                :annulerClick="appliquerClick"
                            >
                                <div>
                                    Vous n'êtes pas éligible au Prêt à taux zéro
                                </div>
                            </confirm>
                        </div>

                    </div>
                    <div class="cadre-pied background-primary">
                        <button
                            class="btn btn-transparent"
                            @click="annuler"
                        >
                            Annuler
                        </button>
                        <button
                            type="submit"
                            class="btn btn-transparent btn-ptz"
                        >
                            Calculer
                        </button>
                    </div>
                </div>
            </div>

        </form>

    </div>

</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'
    import Confirm from "../../../../components/Confirm.vue";

    export default {
        components: {Confirm},
        name: 'Ptz',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema, this.metadataCreer()) : {}
            }
        },
        props: {
            calculer: Function,
            annuler: Function
        },
        data() {
            return {
                formulaire: null,
                schema: {},
                ptzDonnees: {},
                confirmAfficher: false,
            }
        },
        created() {
            this.init(this.$route.params.dossierId)
        },
        computed: {
            customValidations() {
                return this.formulaire.customValidations(this.schema, this.metadataCreer())
            },
        },
        methods: {
            init(dossierId) {
                this.$formulaire.charger('commun', 'Ptz')
                    .then((formulaire) => {
                        this.formulaire = formulaire
                        this.schema = formulaire.schema()

                        api.ptzCharger({dossierId}).then(d => {
                            this.schema = d.data
                        })
                    })
            },
            enregistrer() {
                api.ptzCalculer({dossierId: this.$route.params.dossierId, ptz: this.schema}).then((d) => {
                    this.ptzDonnees = d.data
                    this.confirmAfficher = true
                })
            },
            metadataCreer() {
                return {
                    dossier: this.$dossier,
                    projetAnciennetes: this.$utils.convertirTableau(this.formulaire.constantes.projetAnciennetes, 'id')
                }
            },
            annulerClick(e){
              e.preventDefault()
              this.confirmAfficher = false
            },
            appliquerClick(e) {
                e.preventDefault()
                this.confirmAfficher = false
                this.calculer(this.ptzDonnees)
            },
            selectAction(localite) {
                this.$v.schema.projet_code_postal.$model = localite.postal_code
                this.$v.schema.projet_localite.$model = localite.ville
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../../assets/scss/variables";

    .modal-ptz {
        .btn-ptz {
            margin-left: auto;
            margin-right: 0;
        }

        .modal-content {
            width: calc(100% - 60px) !important;
        }

        .cadre-content {
            display: flex;
            height: calc(100vh - #{$cadre-tete-height} - #{$cadre-pied-height} - 60px);

            > div {
                width: 50%;

                &:first-child {
                    margin-right: 40px;
                }
            }
        }
        .long-checkbox {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div {
                width: 80%;
            }
        }
    }
</style>
