<template>
    <div v-html="texteAffiche">
    </div>
</template>

<script>
    export default {
        name: 'TextEllipsis',
        props: {
            texte: String,
            limite: Number
        },
        computed: {
            texteAffiche() {
                if (!this.texte || this.texte && !this.texte.length) {
                    return 'Pas de précisions'
                }
                const arr = this.texte.split('')
                let final = []
                for (let i = 0; i < this.limite; i++) {
                    final = final.concat(arr[i])
                }
                const textFin = this.limite < arr.length ? '...' : ''
                return `${final.join('')}${textFin}`
            }
        }
    }
</script>
