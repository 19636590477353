<template>
    <form
        class="schema-form"
        @submit="valider"
        novalidate="true"
    >
        <div class="cadre content-cadre background-white">
            <div class="cadre-tete cadre-tete--menu">
                Personne morale

                <formulaire-status
                    :form="$v"
                ></formulaire-status>
            </div>
            <div
                id="formulaire"
                class="cadre-content"
                v-if="formulaire"
            >
                <div
                    id="raison_sociale"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.raison_sociale.$invalid}"
                >

                    <label> Raison sociale * </label>

                    <input
                        type="text"
                        class="form-control"
                        placeholder="Raison sociale"
                        v-model.trim="$v.schema.raison_sociale.$model"
                        autocomplete="disabled"
                    />

                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.raison_sociale.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>

                <div
                    id="siren"
                    class="form-group"
                >
                    <label>N° SIREN</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="SIREN"
                        v-model.trim="$v.schema.siren.$model"
                        autocomplete="disabled"
                    />
                </div>

                <div
                    id="statut_juridique_id"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.statut_juridique_id.$invalid}"
                >

                    <label> Statut juridique * </label>

                    <select
                        class="form-control"
                        v-model.number="$v.schema.statut_juridique_id.$model">
                        <option value="">Sélectionner</option>
                        <option
                            v-for="option in formulaire.constantes.statutsJuridiques"
                            :value="option.id"
                            v-html="option.lib"
                        >
                        </option>
                    </select>

                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.statut_juridique_id.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>

                <div
                    id="registre_type_id"
                    class="form-group"
                >

                    <label> Registre </label>

                    <select
                        class="form-control"
                        v-model.number="$v.schema.registre_type_id.$model">
                        <option value="">Sélectionner</option>
                        <option
                            v-for="option in formulaire.constantes.registres"
                            :value="option.id"
                            v-html="option.code"
                        >
                        </option>
                    </select>
                </div>

                <div
                    id="capital"
                    class="form-group"
                >
                    <label>Capital social</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Capital social"
                        v-model.trim="$v.schema.capital.$model"
                        autocomplete="disabled"
                    />
                </div>

                <div
                    id="registre_ville"
                    class="form-group"
                >
                    <label>Ville RCS ou N°RM</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Ville RCS ou N°RM"
                        v-model.trim="$v.schema.registre_ville.$model"
                        autocomplete="disabled"
                    />
                </div>

                <div
                    id="adresse"
                    class="form-group"
                >

                    <label> Adresse  </label>

                    <input
                        type="text"
                        class="form-control"
                        placeholder="Adresse"
                        v-model.trim="$v.schema.personne_morale_adresse.$model"
                    />
                </div>

                <div
                    id="postal_code"
                    class="form-group"
                >

                    <label> Code postal  </label>

                    <autocomplete-adresse
                        placeholderText="Code Postal"
                        :value="$v.schema.personne_morale_cp.$model"
                        searchKey="postal_code"
                        :selectAction="selectAction"
                        :inputAction="(v) => $v.schema.personne_morale_cp.$model = v"
                    ></autocomplete-adresse>
                </div>

                <div
                    id="ville"
                    class="form-group"
                >

                    <label> Ville  </label>

                    <autocomplete-adresse
                        placeholderText="Ville"
                        :value="$v.schema.personne_morale_ville.$model"
                        searchKey="ville"
                        :selectAction="selectAction"
                        :inputAction="(v) => $v.schema.personne_morale_ville.$model = v"
                    ></autocomplete-adresse>
                </div>


            </div>

            <div class="cadre-pied background-primary">
                <button
                    type="submit"
                    class="btn btn-transparent"
                >
                    Enregistrer
                </button>
            </div>

            <transition
                name="notification-afficher">

                <notification
                    v-if="notification.afficher"
                    :content="notification.texte"
                    :type="'success'"
                ></notification>
            </transition>

            <transition
                name="notification-afficher">
                <notification
                    v-if="enregistrement.afficher"
                    :content="enregistrement.texte"
                    :type="'warning'"
                ></notification>
            </transition>

        </div>

    </form>


</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'

    export default {
        name: 'PersonneMorale',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        beforeRouteUpdate(to, from, next){
            this.scrollTo(to)
            next()
        },
        data() {
            return {
                formulaire: null,
                schema: {}
            }
        },
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema) : {}
            }
        },
        created() {
            this.$formulaire.charger('professionnel', 'PersonneMorale')
                .then((formulaire) => {
                    this.formulaire = formulaire
                    this.schema = formulaire.schema()

                    api.personneMoraleCharger({dossierId: this.$route.params.dossierId}).then((d) => {
                        this.schema = d.data
                        this.scrollTo(this.$route)
                    })
                })
        },
        methods: {
            enregistrer(next = null) {
                return api.personneMoraleModifier({
                    dossierId: this.$route.params.dossierId,
                    personneMorale: this.$v.schema.$model
                })
                    .then(() => {
                        this.notificationAfficher().then(() => {
                            if (!next) {
                                this.menuContinuer()
                            }
                        })
                    })
            },
            selectAction(localite) {
                this.$v.schema.personne_morale_cp.$model = localite.postal_code
                this.$v.schema.personne_morale_ville.$model = localite.ville
            }
        }
    }
</script>

<style lang="scss" scoped>

    .cadre-conteneur {
        height: 100%;

        .cadre {
            background: #fff;
        }
    }

    .nouveau-dossier {
        max-width: 500px;
        height: 100%;
        margin: auto;

        .cadre-pied {
            justify-content: space-between;
        }

        .create-btn {
            width: 100%;
        }
    }

</style>
