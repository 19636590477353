<template>
    <form
        @submit="valider"
        novalidate="true"
    >
        <div class="cadre content-cadre background-white">
            <div class="cadre-tete cadre-tete--menu">
                Endettement locatif
                <formulaire-status
                    :form="$v"
                ></formulaire-status>
            </div>
            <div
                id="formulaire"
                class="cadre-content"
                v-if="formulaire">

                <div
                    id="revenus_locatifs_ponderation"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.revenus_locatifs_ponderation.$invalid}"
                >
                    <label>Pondération sur les revenus locatifs (%) *</label>
                    <input
                        class="form-control"
                        placeholder="Montant"
                        v-model.number="$v.schema.revenus_locatifs_ponderation.$model"
                    />
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.revenus_locatifs_ponderation.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>


                <div
                    id="endettement_locatif_id"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.endettement_locatif_id.$invalid}"
                >
                    <label> Calcul de l'endettement locatif * </label>
                    <select
                        class="form-control"
                        v-model.number="$v.schema.endettement_locatif_id.$model"
                    >
                        <option :value="null">Sélectionner</option>
                        <option v-for="option in formulaire.constantes.endettementTypes"
                                :value="option.id"
                                v-html="option.lib">
                        </option>
                    </select>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.endettement_locatif_id.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>

                <button
                    class="btn btn-large btn-primary"
                    @click="endettementLocatifModalAfficher"
                >
                    Simulation d’endettement locatif
                </button>

            </div>
            <div class="cadre-pied background-primary">
                <button
                    class="btn btn-transparent"
                    @click="e => soumettre(e, 'enregistrerEtRediriger')"
                >
                    Enregistrer +
                    <div class="etape-suivante">passer à l'étape suivante</div>
                </button>
                <button
                    class="btn btn-transparent"
                    @click="e => soumettre(e, 'enregistrer')"
                >
                    Enregistrer
                </button>
            </div>

            <transition
                name="notification-afficher">

                <notification
                    v-if="notification.afficher"
                    :content="notification.texte"
                    :type="'success'"
                ></notification>
            </transition>

            <transition name="modal-appear">
                <modal
                    v-if="modalEndettementLocatif"
                    :close="() => modalEndettementLocatif = false"
                >
                    <EndettementLocatifModal
                        :data="$v.schema.$model"
                        :calculer="endettementLocatifCalculer"
                        :annuler="(e) => annulerEndettementLocatif(e)"
                    ></EndettementLocatifModal>
                </modal>
            </transition>
        </div>
    </form>
</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'
    import EndettementLocatifModal from '@/views/commun/outils-calcul/formulaires/EndettementLocatif.vue'

    export default {
        name: 'EndettementLocatif',
        components: {
            EndettementLocatifModal
        },
        beforeRouteUpdate(to, from, next){
            this.scrollTo(to)
            next()
        },
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema) : {}
            }
        },
        data() {
            return {
                formulaire: null,
                schema: {},
                modalEndettementLocatif: false,
                destination: ''
            }
        },
        created() {
            const {dossierId} = this.$route.params

            this.$formulaire.charger('commun', 'EndettementLocatif', {dossierId: dossierId})
                .then((formulaire) => {
                    this.formulaire = formulaire
                    this.schema = formulaire.schema()

                    return api.endettementLocatifCharger({dossierId})
                        .then(d => {
                            this.schema = d.data
                            this.scrollTo(this.$route)
                        })
                })
        },
        methods: {
            soumettre(e, destination) {
                e.preventDefault()
                this.destination = destination
                this.valider(e)
            },
            enregistrer(next = null) {

                return api.endettementLocatifModifier({
                    dossierId: this.$route.params.dossierId,
                    endettementLocatif: this.schema
                })
                    .then(() => {
                        this.notificationAfficher().then(() => {
                            if (!next) {
                                this.menuContinuer(this.destination)
                            }
                            this.$root.$emit('revenusChargesRafraichir')
                        })
                    })
            },
            endettementLocatifModalAfficher(e){
                e.preventDefault()
                this.modalEndettementLocatif = true
            },
            endettementLocatifCalculer(e, data){
                e.preventDefault()
                this.modalEndettementLocatif = false
                if(data){
                    this.schema = data
                }
            },
            annulerEndettementLocatif(e){
                e.preventDefault()
                this.modalEndettementLocatif = false
            }
        }
    }
</script>
